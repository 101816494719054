import { combineReducers } from 'redux';
import { keyBy, omit } from 'lodash';
import types from './types';
import { userInformationTypes } from '../userInformation';

const friendListReducer = (state = { byId: {}, allIds: [] }, action) => {
  const { type } = action;
  switch (type) {
    case types.getFriendsSuccess: {
      const { friends } = action;
      return { byId: keyBy(friends, 'id'), allIds: friends.map(({ id }) => id) };
    }
    case types.removeFriendSuccess: {
      const { id } = action;
      const { byId, allIds } = state;
      return { byId: omit(byId, id), allIds: allIds.filter(item => item !== id) };
    }
    case types.addFriendSuccess: {
      const { id, data } = action;
      const { byId, allIds } = state;
      return { byId: { ...byId, [id]: data }, allIds: [...allIds, id] };
    }
    default:
      return state;
  }
};

const friendListLoader = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getFriends:
      return true;
    case types.getFriendsSuccess:
    case types.getFriendsFailure:
      return false;
    default:
      return state;
  }
};

const requestCollectionReducer = (state = { byId: {}, allIds: [] }, action) => {
  const { type } = action;
  switch (type) {
    case types.getRequestsSuccess: {
      const { requests } = action;
      return { byId: keyBy(requests, 'id'), allIds: requests.map(({ id }) => id) };
    }
    case types.addFriendSuccess:
    case types.retreatFriendRequestSuccess:
    case types.rejectFriendSuccess: {
      const { id } = action;
      const { byId, allIds } = state;
      return { byId: omit(byId, id), allIds: allIds.filter(item => item !== id) };
    }
    case types.sendFriendRequestSuccess: {
      const { id, data } = action;
      const { byId, allIds } = state;
      return { byId: { ...byId, [id]: { ...data, ownRequest: true } }, allIds: [...allIds, id] };
    }
    default:
      return state;
  }
};

const openFriendRequestsReducer = (state = 0, action) => {
  const { type } = action;
  switch (type) {
    case types.addOpenFriendRequestsSuccess:
      return state + action.count;
    case types.sendFriendRequestSuccess:
      return state - 1;
    case types.retreatFriendRequestSuccess:
      return state + 1;
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.friendRequests !== undefined)
        return info.friendRequests;
      return state;
    }
    default:
      return state;
  }
};

const productReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getFriendsProductsSuccess:
      return action.products;
    default:
      return state;
  }
};

const requestsLoader = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getRequests:
      return true;
    case types.getRequestsSuccess:
    case types.getRequestsFailure:
      return false;
    default:
      return state;
  }
};

const newFriendsReducer = (state = 0, action) => {
  const { type, info } = action;
  switch (type) {
    case userInformationTypes.getUserFeedSuccess: {
      if (info.newFriends !== undefined)
        return info.newFriends;
      return state;
    }
    default:
      return state;
  }
};

const sendingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.sendFriendRequest:
      return true;
    case types.sendFriendRequestFailure:
    case types.sendFriendRequestSuccess:
      return false;
    default:
      return state;
  }
};

const reatreatingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.retreatFriendRequest:
      return true;
    case types.retreatFriendRequestFailure:
    case types.retreatFriendRequestSuccess:
      return false;
    default:
      return state;
  }
};

const buyingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.addOpenFriendRequestsSuccess:
      return true;
    case types.addOpenFriendRequests:
    case types.addOpenFriendRequestsFailure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  list: combineReducers({ items: friendListReducer, isLoading: friendListLoader }),
  requests: combineReducers({ items: requestCollectionReducer, isLoading: requestsLoader }),
  newFriends: newFriendsReducer,
  isSending: sendingReducer,
  isRetreating: reatreatingReducer,
  isBuying: buyingReducer,
  openFriendRequests: openFriendRequestsReducer,
  products: productReducer,
});
