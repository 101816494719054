import React, { useCallback } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { paymentSelectors } from '../payment';

const mapStateToProps = state => ({
  coins: paymentSelectors.getCoins(state),
});

const withCoinAmountCheck = (Component) => {
  const extendedComponent = connect(mapStateToProps)(({ coins, ...props }) => {
    const hasEnoughCoins = useCallback(amount => coins >= amount, [coins]);
    return <Component {...props} hasEnoughCoins={hasEnoughCoins} />;
  });
  hoistNonReactStatics(extendedComponent, Component);
  return extendedComponent;
};

export default withCoinAmountCheck;
