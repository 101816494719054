import { createSelector, createStructuredSelector } from 'reselect';

const getMyGalleryPart = state => state.user.gallery;
const getNextPage = state => getMyGalleryPart(state).nextPage;
const getIsLoading = state => getMyGalleryPart(state).isLoading;
const getMoreRecords = state => getMyGalleryPart(state).moreRecords;
const getImages = state => getMyGalleryPart(state).images;
const getImagesById = state => getImages(state).byId;
const getAllImageIds = state => getImages(state).allIds;
const getIsEditing = state => getMyGalleryPart(state).isEditing;
const getIsUploading = state => getMyGalleryPart(state).isUploading;

const getAllImages = createSelector(
  getImagesById,
  getAllImageIds,
  (items, ids) => ids.map(id => items[id]),
);

const getMyGallery = createStructuredSelector({
  nextPage: getNextPage,
  isLoading: getIsLoading,
  moreRecords: getMoreRecords, 
  images: getAllImages,
});

export default {
  getMyGallery,
  getIsEditing,
  getIsUploading
};
