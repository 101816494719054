import types from './types';

const updateInformation = information => ({ type: types.updateUserInformation, information });
const getUserFeed = () => ({type: types.getUserFeed});
const getUserFeedSuccess = (info) => ({type: types.getUserFeedSuccess, info});
const getUserFeedFailure = (error) => ({type: types.getUserFeedFailure, error});

export default {
  updateInformation,
  getUserFeed,
  getUserFeedSuccess,
  getUserFeedFailure
};
