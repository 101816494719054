import { createSelector } from 'reselect';

const getUserProfilePart = state => state.user.profile;
const getProfileData = state => getUserProfilePart(state).data;
const getProfileId = state => getProfileData(state).id;
const getIsVerified = state => getUserProfilePart(state).isVerified;
const getShowDailyCoins = state => getUserProfilePart(state).showDailyCoins;
const getProfileImage = state => getProfileData(state).image;
const getProfileNotice = state => getUserProfilePart(state).profileNotice;
const getIsEdit = state => getUserProfilePart(state).edit;
const getSendEmail = state => getUserProfilePart(state).sendEmail;
const getCounts = state => getUserProfilePart(state).counts;
const getDownloadedApp = state => getUserProfilePart(state).downloadedApp;
const getIsSaving = state => getUserProfilePart(state).isSaving;
const getStatus = state => getUserProfilePart(state).status;
const getIsNoticeVisible = createSelector(getProfileNotice, notice => notice !== null);
const getLeadLoading = state => getUserProfilePart(state).isLeadLoading;
const getShowWelcomeModal = state => getUserProfilePart(state).showWelcomeModal;
const getStatement = state => getUserProfilePart(state).statement;
const getIsSubscribed = state => getUserProfilePart(state).isSubscribed;
const getNoProfilePicture = state => getUserProfilePart(state).hasNoPicture;
const getCanChangeLocation = state => getUserProfilePart(state).canChangeLocation;

const getIsProfileLoading = createSelector(getProfileId, id => !id);

const getProfile = createSelector(getProfileData, getCounts, (data, counts) => ({
  ...data,
  ...counts,
}));

export default {
  getIsProfileLoading,
  getProfileId,
  getProfile,
  getIsVerified,
  getShowDailyCoins,
  getProfileImage,
  getIsNoticeVisible,
  getProfileNotice,
  getIsEdit,
  getSendEmail,
  getDownloadedApp,
  getIsSaving,
  getProfileData,
  getStatus,
  getLeadLoading,
  getShowWelcomeModal,
  getStatement,
  getIsSubscribed,
  getNoProfilePicture,
  getCanChangeLocation
};
