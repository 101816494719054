const setProfile = 'app/user/profile/SETPROFILE';
const saveProfile = 'app/user/profile/SAVEPROFILE';
const updateProfile = 'app/user/profile/UPDATEPROFILE';
const updateUser = 'app/user/profile/UPDATEUSER';
const saveProfileSuccess = 'app/user/profile/UPDATEPROFILESUCCESS';
const saveProfileFailure = 'app/user/profile/UPDATEPROFILEFAILURE';
const setEdit = 'app/user/profile/EDIT';
const getProfile = 'app/user/profile/GETPROFILE';
const getProfileFailure = 'app/user/profile/GETPROFILEFAILURE';
const setEmailReminder = 'app/user/profile/SETEMAILREMINDER';
const setDailyCoins = 'app/user/profile/SETDAILYCOINS';
const setModalNotice = 'app/user/profile/SETMODALNOTICE';
const acceptModalAlert = 'app/user/profile/ACCEPTMODALALERT';
const declineModalAlert = 'app/user/profile/DECLINEMODALALERT';
const sendVerifyMail = 'app/user/profile/SENDVERIFY';
const sendVerifyMailSuccess = 'app/user/profile/SENDVERFIYSUCCESS';
const sendVerifyMailFailure = 'app/user/profile/SENDVERIFYFAILURE';
const changeEmail = 'app/user/profile/CHANGEEMAIL';
const changeEmailSuccess = 'app/user/profile/CHANGEEMAILSUCCESS';
const changeEmailFailure = 'app/user/profile/CHANGEEMAILFAILURE';
const updateLead = 'app/user/profile/UPDATELEAD';
const updateLeadSuccess = 'app/user/profile/UPDATELEADSUCCESS';
const updateLeadFailure = 'app/user/profile/UPDATELEADFAILURE';
const updateLeadFinish = 'app/user/profile/UPDATELEADFINISH';
const updateLeadFinishSuccess = 'app/user/profile/UPDATELEADFINISHSUCCESS';
const updateLeadFinishFailure = 'app/user/profile/UPDATELEADFINISHFAILURE';
const setStatement = 'app/user/profile/SETSTATEMENT';
const setStatementSuccess = 'app/user/profile/SETSTATEMENTSUCCESS';
const setStatementFailure = 'app/user/profile/SETSTATEMENTFAILURE';
const shownRegisterModal = 'app/user/profile/SHOWNREGISTERMODAL';
const setHasNoPicture = 'app/user/profile/SETHASNOPICTURE';
const setImageDeleted = 'app/user/profile/SETIMAGEDELETED';
const setProfilePic = 'app/user/profile/SETPROFILEIMAGE';
const updateLocation = 'app/user/profile/UPDATELOCATION';
const updateLocationSuccess = 'app/user/profile/UPDATELOCATIONSUCCESS';
const updateLocationFailure = 'app/user/profile/UPDATELOCATIONFAILURE';

export default {
  setProfile,
  saveProfile,
  updateProfile,
  updateUser,
  saveProfileSuccess,
  saveProfileFailure,
  setEdit,
  getProfile,
  getProfileFailure,
  setEmailReminder,
  setDailyCoins,
  setModalNotice,
  acceptModalAlert,
  declineModalAlert,
  sendVerifyMail,
  sendVerifyMailSuccess,
  sendVerifyMailFailure,
  changeEmail,
  changeEmailSuccess,
  changeEmailFailure,
  updateLead,
  updateLeadSuccess,
  updateLeadFailure,
  updateLeadFinish,
  updateLeadFinishSuccess,
  updateLeadFinishFailure,
  setStatement,
  setStatementSuccess,
  setStatementFailure,
  shownRegisterModal,
  setHasNoPicture,
  setImageDeleted,
  setProfilePic,
  updateLocation,
  updateLocationSuccess,
  updateLocationFailure
};
