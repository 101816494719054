import { take, fork } from 'redux-saga/effects';
import { differenceInSeconds } from 'date-fns';

function* takeTimed(type, saga, interval) {
  let lastTime = null;
  while (true) {
    const action = yield take(type);
    if (!lastTime || differenceInSeconds(new Date(), lastTime) > interval) {
      yield fork(saga, action);
      lastTime = new Date();
    }
  }
}

export default takeTimed;