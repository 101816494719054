import {keyBy, pick, uniq} from 'lodash';
import types from './types';

const reducer = (state = { byId: {}, allIds: [] }, action) => {
  const { type } = action;
  switch (type) {
    case types.addItems: {
      const { items } = action;
      const { byId, allIds } = state;
      return {
        byId: { ...byId, ...keyBy(items, 'id') },
        allIds: uniq([...allIds, ...items.map(({ id }) => id)]),
      };
    }
    case types.removeGallery: {
      const { galleryId } = action;
      const { allIds, byId } = this.state;
      const remainingIds = allIds.reduce(
        (array, id) => (byId[id].galleryId !== galleryId ? [...array, id] : array),
        [],
      );
      return { allIds: remainingIds, byId: pick(byId, remainingIds) };
    }
    case types.unlockItem:
    case types.unlockItemSuccess:
    case types.unlockItemFailure: {
      const { byId, allIds } = state;
      const { id } = action;
      return { allIds, byId: { ...byId, [id]: itemReducer(byId[id], action) } };
    }
    default:
      return state;
  }
};

const itemReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.unlockItem:
      return { ...state, loading: true };
    case types.unlockItemSuccess: {
      const { loading, error, ...item } = state;
      const { values } = action;
      return { ...item, ...values };
    }
    case types.unlockItemFailure: {
      const { loading, ...item } = state;
      const { error } = action;
      return { ...item, error };
    }
    default:
      return state;
  }
};

export default reducer;
