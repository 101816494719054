import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  padding-top: ${({ ratio }) => ratio * 100}%;
  width: 100%;
  position: relative;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
/**
 * Custom Box for fit height of div
 */
const AspectRatioBox = ({ aspectRatio, children, ...props }) => (
  <Box ratio={aspectRatio}>
    <div {...props}>{children}</div>
  </Box>
);

export default AspectRatioBox;
