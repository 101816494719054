import styled from 'styled-components';
import StyleGuide from './StyleGuide';

const CenterDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  background-color: ${props => props.background || 'white'};
  @media only screen and (max-width: 768px) {
  }
`;

const RoundedDiv = styled(CenterDiv)`
  border-radius: 10px;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : StyleGuide.colors.surface};
  flex: ${({ flex }) => (flex ? 1 : 0)};
  height: 55px;
  border-color: ${StyleGuide.colors.fontOnSecondary};
  border-width: ${props => (props.border ? '2px' : '0px')};
  border-style: solid;
  ${StyleGuide.boxShadowHigh};

  @media only screen and (max-width: 414px) {
    width: auto;
  }
`;

const RoundedButton = styled(CenterDiv)`
border-radius: 10px;
background: ${props => props.backgroundColor ? props.backgroundColor : StyleGuide.colors.surface};
flex-direction: row;
flex:${props => props.flex || 0};
width: ${props => props.width || 'unset'};
height: 50px;
min-height: 50px;
${props => (props.justifyContent ? `justify-content:${props.justifyContent};` : null)}
border-color: ${StyleGuide.colors.fontOnSecondary};

border-width: ${props => (props.border ? '2px' : '0px')};
cursor: ${props => (props.noPointer ? '' : 'pointer')} ;
  ${StyleGuide.boxShadowButton};
`;

const Background = styled.div`
  width: 100%;
  height: ${props => (props.fill ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  overflow: ${({ scroll }) => (scroll ? 'auto' : 'visible')};
  background-attachment: fixed;
  background-color: ${({theme: { backgroundPrimary }}) => backgroundPrimary || StyleGuide.colors.lightGrey};
`;

const RoundedBorderDiv = styled(RoundedDiv)`
  border-color: ${StyleGuide.colors.fontOnPrimary};
  border: 1px solid;
`;

const Text = styled.p`
  font-weight: ${props => (props.fat ? '500' : 'inherit')};
  color: ${props => props.color || StyleGuide.colors.fontOnPrimary};
  text-align: ${props => props.textAlign || 'center'};
  margin: ${props => props.margin || '5px'};
  font-size: ${props => props.size};
  word-wrap: normal;
`;

const DimBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const ThemedText = styled.p`
  color: ${props => props.color || props.theme.main || StyleGuide.colors.fontOnPrimary};
  margin: ${props => props.margin || '0px'};
  text-align: ${props => props.textAlign || 'center'};
  font-weight: ${props => props.fontWeight || 'inherit'};
`;

const Title = styled.h2`
  text-align: center;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0;
  padding: 0.83em 0;
  color: ${props => props.theme.main || 'black'};
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

const TextCursive = styled(Text)`
  font-size: 1em;
  text-decoration: underline;
  :hover{
    opacity: 0.5;
  }
`;

const Input = styled.input`
  outline: inherit;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  width: ${props => (props.stretch ? '100%' : '')};
  color: ${props => props.color || StyleGuide.colors.fontOnSurface};
  margin: ${props => props.margin || '0px'};
`;

const Image = styled.img`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  margin: ${props => props.margin || '0px'};
`;

const Form = styled.form``;

const Label = styled.label`
  color: ${({color, theme: {inputFont}}) => color || inputFont};
  text-align: ${props => props.alignSelf || 'left'};
  cursor: ${props => (props.pointer ? 'pointer' : '')};
  font-size:inherit;
  @media only screen and (max-width: 414px) {
    font-size: .9em;
  }
`;

const Link = styled.a``;

const MobileSearchButton = styled.div`
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  ${StyleGuide.boxShadowHigh};
  background: ${props => props.theme.background || 'lightgrey'};
  justify-content: center;
  align-items: center;
  padding: 5px;
  @media only screen and (max-width: 414px) {
    display: flex;
  }
`;

const ThemedContainerTitle = styled.p`
font-size: .8em;
margin: 5px 0;
color: ${({theme: {main}}) => main};
font-weight: 500;
`;

export {
  CenterDiv,
  RoundedDiv,
  Background,
  RoundedBorderDiv,
  Text,
  Image,
  Input,
  Form,
  RoundedButton,
  Link,
  Label,
  TextBold,
  TextCursive,
  Title,
  ThemedText,
  DimBackground,
  MobileSearchButton,
  ThemedContainerTitle
};
