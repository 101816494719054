const setPriceList = 'app/payment/coins/SETPRICELIST';
const addAmount = 'app/payment/coins/ADDAMOUNT';
const removeAmount = 'app/payment/coins/REMOVEAMOUNT';
const buyCoins = 'app/payment/BUYCOINS';
const buyCoinsFailure = 'app/payment/BUYCOINSFAILURE';
const getProducts = 'app/payment/products/GET';
const getProductsSuccess = 'app/payment/products/GETSUCCESS';
const getProductsFailure = 'app/payment/products/GETFAILURE';
const getPriceList = 'app/payments/GETPRICELIST';
const getPriceListSuccess = 'app/payments/GETPRICELISTSUCCESS';
const getPriceListFailure = 'app/payments/GETPRICELISTFAILURE';
const buyMoreCoins = 'app/payments/BUYMORECOINS';
const setFinishedTransaction = 'app/payments/SETFINISHEDTRANSACTION';

export default {
  setPriceList,
  addAmount,
  removeAmount,
  buyCoins,
  buyCoinsFailure,
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  getPriceList,
  getPriceListSuccess,
  getPriceListFailure,
  buyMoreCoins,
  setFinishedTransaction,
};
