import React, { useEffect } from 'react';
import Head from 'next/head';
import Config from '../config';
/**
 * Simple Component setting the Title
 */
const Title = ({ text }) => {

  return (
    <Head>
      <title>{text || Config.appName}</title>
    </Head>
  );
};

export default Title;
