const Config = {
  dev: true,
  api: {
    url:
      process.env.NODE_ENV === 'production'
        ? 'https://api.icatched.de/api/'
        : 'https://api.icatched.de/api/', /*'https://staging.icatched.de/api/'*/
  },
  cacheAmount: 10,
  urls: {
    imprint: 'https://icatched.de/impressum/',
    privacy: 'https://icatched.de/datenschutz/',
    tos: 'https://icatched.de/agb',
    faq: 'https://icatched.de/faq',
    forgotPassword: 'https://icatched.de/auth/reset-request/',
    guidelines: 'https://icatched.de/richtlinien/',
    googlePlaystore: 'https://app.adjust.com/agvohw4',
    appleStore: 'https://app.adjust.com/psyin0f',
  },
  places: {
    key: 'AIzaSyD2mfr5r8qlQRkmDkkDsOSKThWqgvqfxKQ',
  },
  maxImagePrice: 10000,
  s3: {
    dirName: 'app/tickets/media_upload',
    bucketName:
      process.env.NODE_ENV === 'production'
        ? 'media.icatchedfiles.de'
        : 'media.icatchedfiles.de', /*'mediadev.icatchedfiles.de'*/
    region: 'eu-central-1',
    accessKeyId: 'AKIA257POBS7WP7C2A74',
    secretAccessKey: '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',
    s3Url:
      process.env.NODE_ENV === 'production'
        ? 'https://s3.eu-central-1.amazonaws.com/media.icatchedfiles.de/'
        : 'https://s3.eu-central-1.amazonaws.com/media.icatchedfiles.de/', /*'https://s3.eu-central-1.amazonaws.com/mediadev.icatchedfiles.de/'*/
  },
  versionNumber: 'Version 2.0.1 Build 1',
  appName: 'iCatched',
  appCurrency: 'Coins',
  appCurrencySymbol: '₵',
  acceptedTypes: ['image/jpg', 'image/jpeg', 'image/png'],
  appsFlyer: {
    webKey: 'f904e394-8282-48ea-aec0-4e2f9cf4f5fd',
  },
  availableCountries: ['Germany', 'Switzerland', 'Austria'],
  recaptcha: {
    webKey: '6LcdQG4aAAAAAGonK3e1Y39ABpdvf901E1OFpN4q',
    secretKey: '6LcdQG4aAAAAAHo0IO5UORB1i03Hl8ytkHla0uNX',
  },
};

export default Config;
