import {
  takeLatest, call, put, takeEvery, select,
} from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import {
  getFriends,
  getFriendRequests,
  sendFriendRequest,
  denyFriendRequest,
  acceptFriendRequest,
  removeFriend,
  getFriendProducts,
  buyInternalService,
} from '../services/webAppEndPoint';
import { paymentFunctions } from '../payment';
import Tracking from '../tracking';
import { takeUniq } from '../sagas';

function* getFriendList() {
  try {
    const friends = yield call(getFriends);
    yield put(actions.getFriendsSuccess(friends));
  } catch (error) {
    yield put(actions.getFriendsFailure(error));
  }
}

function* handleGetFriends() {
  yield takeLatest(types.getFriends, getFriendList);
}

function* getRequests() {
  try {
    const requests = yield call(getFriendRequests);
    yield put(actions.getRequestsSuccess(requests));
  } catch (error) {
    yield put(actions.getRequestsFailure(error));
  }
}

function* denyRequest({ id }) {
  try {
    yield call(denyFriendRequest, id);
    yield put(actions.rejectFriendSuccess(id));
  } catch (error) {
    yield put(actions.rejectFriendFailure(id, error));
  }
}

function* handleRejectFriend() {
  yield takeEvery(types.rejectFriend, denyRequest);
}

function* acceptRequest({ id }) {
  try {
    const user = yield select(
      ({
        friends: {
          requests: { items },
        },
      }) => items.byId[id],
    );
    yield call(acceptFriendRequest, id);
    yield put(actions.addFriendSuccess(id, user));
  } catch (error) {
    yield put(actions.addFriendFailure(error));
  }
}

function* handleAddFriend() {
  yield* takeUniq(types.addFriend, acceptRequest, ({id}) => id);
}

function* retreatRequest({ id }) {
  try {
    yield call(removeFriend, id);
    yield put(actions.retreatFriendRequestSuccess(id));
  } catch (error) {
    yield put(actions.retreatFriendRequestFailure(id, error));
  }
}

function* handleRetreat() {
  yield* takeUniq(types.retreatFriendRequest, retreatRequest, ({id}) => id);
}

function* makeFriendRequest({ id }) {
  try {
    const data = yield call(sendFriendRequest, id);
    yield put(actions.sendFriendRequestSuccess(id, data));
  } catch (error) {
    yield put(actions.sendFriendRequestFailure(id, error));
  }
}

function* handleMakeFriends() {
  yield* takeUniq(types.sendFriendRequest, makeFriendRequest, ({id}) => id);
}

function* deleteFriend({ id }) {
  try {
    yield call(removeFriend, id);
    yield put(actions.removeFriendSuccess(id));
  } catch (error) {
    yield put(actions.removeFriendFailure(id, error));
  }
}

function* handleRemoveFriend() {
  yield* takeUniq(types.removeFriend, deleteFriend, ({id}) => id);
}

function* handleGetFriendRequests() {
  yield takeLatest(types.getRequests, getRequests);
}

function* getProducts() {
  try {
    const products = yield call(getFriendProducts);
    yield put(actions.getFriendsProductsSuccess(products));
  } catch (error) {
    yield put(actions.getFriendsProductsFailure(error));
  }
}

function* handleSetOpenFriendRequests() {
  yield takeLatest(types.getFriendsProducts, getProducts);
}

function* addMoreRequests({ id, price, count }) {
  try {
    yield call(buyInternalService, id);
    yield* paymentFunctions.finishTransaction(price);
    Tracking.track(`friendsNewSlots.${count}`);
    yield put(actions.addOpenFriendRequestsSuccess(count));
  } catch (error) {
    yield put(actions.addOpenFriendRequestsFailure(error));
  }
}

function* handleAddOpenFriendRequests() {
  yield takeLatest(types.addOpenFriendRequests, addMoreRequests);
}

export default {
  handleGetFriends,
  handleGetFriendRequests,
  handleAddFriend,
  handleRejectFriend,
  handleRemoveFriend,
  handleRetreat,
  handleMakeFriends,
  handleSetOpenFriendRequests,
  handleAddOpenFriendRequests,
};
