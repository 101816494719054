const getFriends = 'app/friends/list/GET';
const getFriendsSuccess = 'app/friends/list/GETSUCCESS';
const getFriendsFailure = 'app/friends/list/GETFAILURE';
const addFriend = 'app/friends/ADD';
const addFriendSuccess = 'app/friends/ADDSUCCESS';
const addFriendFailure = 'app/friends/ADDFAILURE';
const rejectFriend = 'app/friends/REJECT';
const rejectFriendSuccess = 'app/friends/REJECTSUCCESS';
const rejectFriendsFailure = 'app/friends/REJECTFAILURE';
const retreatFriendRequest = 'app/friends/RETREAT';
const retreatFriendRequestSuccess = 'app/friends/RETREATSUCCESS';
const retreatFriendRequestFailure = 'app/friends/RETREATFAILURE';
const getRequests = 'app/friends/requests/GET';
const getRequestsSuccess = 'app/friends/requests/GETSUCCESS';
const getRequestsFailure = 'app/friends/requests/GETFAILURE';
const removeFriend = 'app/friends/REMOVE';
const removeFriendSuccess = 'app/friends/REMOVESUCCESS';
const removeFriendFailure = 'app/friends/REMOVEFAILURE';
const sendFriendRequest = 'app/friends/SENDFRIENDREQUEST';
const sendFriendRequestSuccess = 'app/friends/SENDFRIENDREQUESTSUCCESS';
const sendFriendRequestFailure = 'app/friends/SENDFRIENDREQUESTFAILURE';
const addOpenFriendRequests = 'app/friends/requests/ADD';
const addOpenFriendRequestsSuccess = 'app/friends/requests/ADDSUCCESS';
const addOpenFriendRequestsFailure = 'app/friends/requests/ADDFAILURE';
const getFriendsProducts = 'app/friends/requests/PRODUCTS';
const getFriendsProductsSuccess = 'app/friends/requests/PRODUCTSUCCESS';
const getFriendsProductsFailure = 'app/friends/requests/PRODUCTFAILURE';

export default {
  getFriends,
  getFriendsSuccess,
  getFriendsFailure,
  addFriend,
  addFriendSuccess,
  addFriendFailure,
  rejectFriend,
  rejectFriendSuccess,
  rejectFriendsFailure,
  getRequests,
  getRequestsSuccess,
  getRequestsFailure,
  removeFriend,
  removeFriendSuccess,
  removeFriendFailure,
  sendFriendRequest,
  sendFriendRequestSuccess,
  sendFriendRequestFailure,
  retreatFriendRequest,
  retreatFriendRequestSuccess,
  retreatFriendRequestFailure,
  addOpenFriendRequests,
  addOpenFriendRequestsFailure,
  addOpenFriendRequestsSuccess,
  getFriendsProducts,
  getFriendsProductsSuccess,
  getFriendsProductsFailure,
};
