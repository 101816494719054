import React from 'react';
import styled, { withTheme } from 'styled-components';
import Icon from './Icon';
import StyleGuide from './StyleGuide';

const ButtonContainer = styled.div`
  border-radius: 50%;
  padding: 3px;
  background-color: ${({backgroundColor, theme:{input}}) => backgroundColor || input};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin || '5px'};
  cursor: pointer;
  ${StyleGuide.boxShadowHigh};
  :hover {
    opacity: 0.8;
  }
`;
/**
 * Button Component with integrated Icon 
 */
const IconButton = ({
  iconName,
  iconColor,
  iconSize = 20,
  onClick = () => { },
  highlighted = false,
  theme,
  ...props
}) => (
  <ButtonContainer onClick={onClick} {...props}>
    <Icon
      name={iconName}
      color={highlighted ? StyleGuide.colors.fontOnPrimary : iconColor || theme.main}
        // color={highlighted ? StyleGuide.colors.fontOnPrimary : iconColor || 'lightgrey'}
      size={iconSize}
    />
  </ButtonContainer>
  );

export default withTheme(IconButton);
