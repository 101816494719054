import firebase from 'firebase/app';
import TagManager from 'react-gtm-module';
// import * as firebase from "firebase";
import 'firebase/analytics';
import { mapValues } from 'lodash';
import analyticsMap from './mappings/analytics';
import appsFlyerMap from './mappings/appsFlyer';

export function initTracking() {
  // const analytics = firebase.analytics();
  firebase.analytics().setAnalyticsCollectionEnabled(true);
}

export function stopTracking() {}

function checkTreeForPath(arrayPath, substance, depth = 0) {
  const pathFragment = arrayPath.length > depth ? arrayPath[depth] : null;
  if (!pathFragment) {
    return substance;
  }
  return substance[pathFragment]
    ? checkTreeForPath(arrayPath, substance[pathFragment], depth + 1)
    : substance;
}

function trackAnalytics(action, additionalParams) {
  const actionPath = action.split('.');
  const event = checkTreeForPath(actionPath, analyticsMap);
  if (event.category) {
    const data = {
      dataLayer: {
        event: event.category,
        eventAction: event.action,
        ...additionalParams,
      },
    };
    TagManager.dataLayer(data);
  }
}

function trackAppsFlyerEvent(action, additionalParams) {
  if (!window?.AF) return;

  const actionPath = action.split('.');
  const event = checkTreeForPath(actionPath, appsFlyerMap);
  if (typeof event === 'string') {
    if (process.env.NODE_ENV === 'production')
      window?.AF('pba', 'event', { eventType: 'EVENT', eventName: event });
    else console.log('Tried to log: ', { eventType: 'EVENT', eventName: event });
  } else if (event?.event) {
    const { event: eventName, ...params } = event;
    const eventParams = mapValues(params, (value, key) => value ?? additionalParams[key]);
    if (process.env.NODE_ENV === 'production')
      window?.AF('pba', 'event', { eventType: 'EVENT', eventName, eventValue: eventParams });
    else console.log('Tried to log: ', { eventType: 'EVENT', eventName, eventValue: eventParams });
  }
}

export function track(action, additionalParams, onlyAnalytics = false) {
  trackAnalytics(action, additionalParams);
  if (onlyAnalytics) return;
  trackAppsFlyerEvent(action, additionalParams);
}

export function trackAppsFlyer(action, additionalParams) {
  trackAppsFlyerEvent(action, additionalParams);
}
