import { createStructuredSelector, createSelector } from 'reselect';

const getFriendPart = state => state.friends;
const getFriends = state => getFriendPart(state).list;
const getFriendsById = state => getFriends(state).items.byId;
const getFriendsIds = state => getFriends(state).items.allIds;
const getFriendsLoading = state => getFriends(state).isLoading;
const getRequests = state => getFriendPart(state).requests;
const getRequestsById = state => getRequests(state).items.byId;
const getRequestsIds = state => getRequests(state).items.allIds;
const getRequestsLoading = state => getRequests(state).isLoading;
const getProducts = state => getFriendPart(state).products;
const getIsBuying = state => getFriendPart(state).isBuying;
const getIsSending = state => getFriendPart(state).isSending;
const getIsRetreating = state => getFriendPart(state).isRetreating;
const getNewFriends = state => getFriendPart(state).newFriends;
const getOpenFriendRequests = state => getFriendPart(state).openFriendRequests;

const getBuyFriendPackages = createStructuredSelector({
  products: getProducts,
  isBuying: getIsBuying,
});

const getAllFriends = createSelector(
  getFriendsById,
  getFriendsIds,
  (byId, ids) => ids.map(id => byId[id]),
);
const getAllRequests = createSelector(
  getRequestsById,
  getRequestsIds,
  (byId, ids) => ids.map(id => byId[id]),
);

const canSendFriendRequest = createSelector(
  getOpenFriendRequests,
  count => count > 0,
);

export default {
  getBuyFriendPackages,
  getAllFriends,
  getAllRequests,
  getFriendsLoading,
  getRequestsLoading,
  getOpenFriendRequests,
  getNewFriends,
  canSendFriendRequest,
  getIsSending,
  getIsRetreating,
  getIsBuying
};
