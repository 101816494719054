import { useState, useEffect, useMemo, useCallback } from 'react';

const useMedia = queries => {
  const mediaQueries = useMemo(() => queries.map(query => window.matchMedia(query)), [queries]);

  const getValue = useCallback(
    () => mediaQueries.reduce((bool, query) => query.matches && bool, true),
    [mediaQueries]
  );
  const [value, setValue] = useState(getValue);
  useEffect(() => {
    let active = true;
    function updateValue() {
      if (active) {
        setValue(getValue());
      }
    }
    mediaQueries.forEach(query => query.addListener(updateValue));
    return () => {
      active = false;
      mediaQueries.forEach(query => query.removeListener(updateValue));
    };
  }, [mediaQueries, getValue]);
  return value;
};

export default useMedia;
