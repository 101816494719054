import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { isEmpty, sortBy } from 'lodash';
import Icon from './Icon';
import { RoundedButton, Input, Text } from './ThemedComponents';
import StyleGuide from './StyleGuide';
import UseOutsideClick from './UseOutsideClick';
import { useTranslation } from '../i18n';
import { GetAllowedCountries } from '../services/webAppEndPoint';
import { useLocationPrediction } from '../hooks';
import FlagIcon from './FlagIcon';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

const Col = styled(RoundedButton)`
  position: relative;
  cursor: initial;
  margin: 0px 5px;
  margin-right: 0px;
  margin-bottom: ${({ marked }) => (marked ? '5px' : '0px')};
  flex: 1;
  justify-content: flex-start;
  background-color: ${({ theme: { input } }) => input};
`;

const FlagContainer = styled(RoundedButton)`
  position: relative;
  margin: 0px 5px;
  margin-left: 0px;
  padding: 0px 10px;
  width: min-content;
  background-color: ${({ theme: { input } }) => input};
`;

const PredictionContainer = styled.div`
  position: absolute;
  border: 1px solid ${StyleGuide.colors.primary};
  border-bottom: none;
  border-top: none;
  min-width: min-content;
  max-height: 200px;
  overflow: auto;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${({ theme: { input } }) => input};
  border-radius: 0px 0px 10px 10px;
  background-color: ${({ theme: { input } }) => input};
  padding: 5px 0px;
  margin-top: -5px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  ${StyleGuide.boxShadowHigh};
  z-index: 1000;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
const Inpu = styled(Input)`
  width: 100%;
  color: ${({ theme: { inputFont }, marked }) => (marked ? StyleGuide.colors.decline : inputFont)};
`;

const Prediction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${StyleGuide.colors.primary};
  :last-child {
    border-bottom: none;
  }
  align-self: stretch;
  margin: 0px 20px;
  padding: 7px 5px;
`;

const ErrorText = styled(Text)`
  color: ${StyleGuide.colors.decline};
  font-size: 0.7em;
  position: absolute;
  bottom: -20px;
  left: 10px;
`;

const Flag = styled.img`
  width: 25px;
  min-width: 25px;
  margin: 5px 7px;
  height: 18px;
`;

// regex to filter => /, \, =, @, >, <, (), more than 2 digits
const isInvalidCityName = (city) => {
  const reg = /(\/|=|@|\+|\\|<|>|\(|\)|\d{3})/;
  return reg.test(city);
};

/**
 * Custom Text Input Component with prediction and Icon
 */
const InputLocation = ({ geoId, setGeoId, setCityName }) => {
  const { t } = useTranslation(['settings', 'common', 'login']);
  const theme = useContext(ThemeContext);
  const [error, setError] = useState(false);
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [location, setLocation] = useState('');
  const [showCountries, setShowCountries] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [cityPredictions, setCityPredictions] = useState([]);
  const getNewPredictions = useLocationPrediction();

  const checkedCities = useMemo(() => {
    if (cityPredictions?.length === 0) return [];
    const duplicateCounter = {};
    cityPredictions.forEach((item) => {
      if (duplicateCounter[item?.city]) duplicateCounter[item?.city] += 1;
      else duplicateCounter[item?.city] = 1;
    });

    return cityPredictions.map((item) =>
      duplicateCounter[item?.city] > 1
        ? { ...item, displayName: `${item?.city}, ${item?.admin_name}` }
        : { ...item, displayName: item?.city }
    );
  }, [cityPredictions]);

  // fetching allowed Countries
  useEffect(() => {
    if (countries?.length > 0) return;
    GetAllowedCountries()
      .then((items) => {
        setCountries(items);
        const defaultLang = items.find(({ iso }) => iso === window?.navigator?.language);
        setCountry(defaultLang || { iso: 'de', uri: 'https://flagcdn.com/48x36/de.png' });
      })
      .catch(() => {
        setCountries([{ iso: 'de', uri: 'https://flagcdn.com/48x36/de.png' }]);
      });
  }, [countries?.length]);

  // fetching Cities
  useEffect(() => {
    async function setNewPredictions() {
      const value = await getNewPredictions(location, country?.iso);
      if (!geoId) setShowCities(true);
      setCityPredictions(value);
    }
    if (!geoId && location?.length > 2) {
      if (error) setShowCities(false);
      else setNewPredictions();
    } else {
      setCityPredictions([]);
    }
  }, [getNewPredictions, location, geoId, country, error]);

  // Begin Stuff for OutsideClicks
  const outsideRefCountry = useRef();
  const outsideRefCity = useRef();

  UseOutsideClick(outsideRefCountry, () => {
    if (showCountries) {
      setShowCountries(false);
    }
  });

  UseOutsideClick(outsideRefCity, () => {
    if (showCities) {
      setShowCities(false);
    }
  });
  // End Stuff for OutsideClicks

  const sortedCountries = useMemo(() => {
    if (!countries || countries?.length === 0) return [];
    return countries
      .map((item) => {
        return { ...item, transName: t([`countries.${item?.iso}`, item?.name]) };
      })
      .sort((a, b) => a.transName.localeCompare(b.transName));
  }, [countries, t]);

  return (
    <Wrapper>
      <FlagContainer
        ref={outsideRefCountry}
        onClick={() => {
          setShowCountries(!showCountries);
          setShowCities(false);
          setGeoId(null);
          setLocation('');
        }}
      >
        {country && !isEmpty(country) && country?.uri ? (
          <FlagIcon flag={country?.iso} styleObject={{ margin: '1px 3px' }} />
        ) : (
          // <Flag src={country?.uri} alt={country?.iso || 'flag'} />
          <Icon name="flag" color={theme.inputIcon} size={25} />
        )}
        <PredictionContainer visible={showCountries}>
          {sortedCountries?.map((prediction) => (
            <Prediction
              onClick={() => {
                setCountry(prediction);
                setShowCountries(false);
                setError(false);
              }}
            >
              {/* <Flag src={prediction?.uri} alt={prediction?.iso || 'flag'} /> */}
              <FlagIcon flag={prediction?.iso} styleObject={{ margin: '1px 3px' }} />
              <Text color={theme.inputFont} textAlign="left">
                {prediction?.transName}
              </Text>
            </Prediction>
          ))}
        </PredictionContainer>
      </FlagContainer>
      <Col ref={outsideRefCity} marked={error}>
        <Inpu
          type="text"
          marked={error}
          value={location}
          placeholder={t('login:locationPlaceholder')}
          onChange={({ target: { value: val } }) => {
            setError(false);
            if (isInvalidCityName(val)) {
              setError(true);
            }
            setLocation(val);
            setGeoId(null);
          }}
          margin="0px 10px"
          disabled={!country || isEmpty(country)}
        />
        {error && <ErrorText>{t('login:locationError')}</ErrorText>}
        <PredictionContainer visible={showCities}>
          {cityPredictions?.length === 0 && (
            <Text margin="7px 10px" color={theme.inputFont} textAlign="left">
              {t('common:noResults')}
            </Text>
          )}
          {checkedCities.map((prediction) => (
            <Prediction
              onClick={() => {
                setGeoId(prediction?.id);
                if (setCityName) setCityName(prediction?.city);
                setLocation(prediction?.city);
                setShowCities(false);
              }}
            >
              <Text color={theme.inputFont} textAlign="left">
                {prediction?.displayName || prediction?.city}
              </Text>
            </Prediction>
          ))}
        </PredictionContainer>
      </Col>
    </Wrapper>
  );
};
export default InputLocation;
