import { put, call, takeEvery } from 'redux-saga/effects';
import { paymentFunctions } from '../payment';
import types from './types';
import actions from './actions';
import { unlockImage } from '../services/webAppEndPoint';
import Tracking from '../tracking';
import { takeUniq } from '../sagas';

function* unlock({ id, value }) {
  try {
    const values = yield call(unlockImage, id);
    yield put(actions.unlockItemSuccess(id, values));
    // Tracking.track('profileUnlock');
    yield* paymentFunctions.finishTransaction(value);
  } catch (error) {
    yield put(actions.unlockItemFailure(id, error));
  }
}

function* handleUnlockGalleryItem() {
  yield* takeUniq(types.unlockItem, unlock, ({id}) => id);
}

export default { handleUnlockGalleryItem };
