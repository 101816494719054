import { call, put, takeLatest, take, select, takeLeading } from 'redux-saga/effects';
import actions from './actions';
import types from './types';
import { GetProducts, GetPriceList } from '../services/webAppEndPoint';
import loginTypes from '../login/types';
import profileTypes from '../profile/types';

function* getProducts() {
  try {
    const products = yield call(GetProducts);
    yield put(actions.getProductsSuccess(products));
  } catch (error) {
    yield put(actions.getProductsFailure(error));
  }
}

function* getPriceList() {
  try {
    const priceList = yield call(GetPriceList);
    yield put(actions.getPriceListSuccess(priceList));
  } catch (error) {
    yield put(actions.getPriceListFailure(error));
  }
}

function* buyProduct({
  product: { id, count, price, currency, extraCoins },
  screenName,
  actionName,
  paymentMethod = {},
}) {
  try {
    console.log("buy");
  } catch (error) {
    yield put(actions.buyCoinsFailure(error));
  } finally {
    yield put(actions.setFinishedTransaction(true));
  }
}

export function* finishTransaction(value) {
  yield put(actions.removeAmount(value));
}

function* handleBuyCoins() {
  yield takeLeading(types.buyCoins, buyProduct);
}

function* handleGetProducts() {
  yield takeLatest(
    [loginTypes.loginSuccess, loginTypes.registerSuccess, types.getProducts],
    getProducts
  );
}

function* handleGetPriceList() {
  yield takeLatest(
    [loginTypes.loginSuccess, loginTypes.registerSuccess, types.getPriceList, profileTypes.updateLocationSuccess],
    getPriceList
  );
}

export default {
  handleGetProducts,
  handleBuyCoins,
  handleGetPriceList,
};
