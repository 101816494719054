import { css } from 'styled-components';

const boxShadowHigh = css`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
`;

const boxShadowLow = css`
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.5);
`;

const boxShadowButton = css`
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.30);
`;

const backgroundGradient = css`
  background: linear-gradient(135deg, #956d58, #be917a 50%, #956d58);
`;

const buttonGradient = css`
  background: linear-gradient(to right, #7ac8f7, #cce2f0 40%, #7ac8f7);
`;

const surfaceGradient = css`
  background-image: linear-gradient(#f2f0f0, #ffffff);
`;

export default {
  colors: {
    primary: '#507FFF',
    primaryVariantLight: '#66ACFF',
    primaryVariantUltraLight: '#D1E7F9',
    primaryVariantDark: '#0054B7',
    primaryGradient: 'linear-gradient(180deg, #3ABAFF 0%, #1B84FF 100%)',
    secondary: '',
    secondaryVariant: '',
    background: '#ffffff',
    backgroundFooterLight: '#ffffff',
    backgroundFooterDark: '#696969',
    surface: '#ffffff',
    // error: '#F7CCCD',
    error: 'lightgrey',
    footer: '#282c34',
    fontOnPrimary: '#ffffff',
    fontOnSecondary: '#000000',
    fontOnBackground: '#ffffff',
    fontOnSurface: '#000000',
    // fontOnError: '#ffffff',
    fontOnError: '#000000',
    fontOnFooterDark: '#696969',
    facebook: '#3A5B9B',
    google: '#DD4B39 ',
    disabled: '#F5BABC',
    yellow: '#F5A623',
    lightGrey: '#fbfbfb',
    dimGrey: '#EFF3F4',
    accept: '#1D9D3E',
    decline: '#D70005',
    grey: '#636161',
  },
  images: {
    header_background: '/static/images/header_background.png',
  },
  shadows: {
    high: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
    none: '0px 0px 0px 0px rgba(0, 0, 0, 0.5)',
  },
  boxShadowLow,
  boxShadowHigh,
  backgroundGradient,
  boxShadowButton,
  buttonGradient,
  surfaceGradient,
};
