import types from './types';

const getFriends = () => ({ type: types.getFriends });
const getFriendsSuccess = friends => ({ type: types.getFriendsSuccess, friends });
const getFriendsFailure = error => ({ type: types.getFriendsFailure, error });
const addFriend = id => ({ type: types.addFriend, id });
const addFriendSuccess = (id, data) => ({ type: types.addFriendSuccess, id, data });
const addFriendFailure = (id, error) => ({ type: types.addFriendFailure, id, error });
const rejectFriend = id => ({ type: types.rejectFriend, id });
const rejectFriendSuccess = id => ({ type: types.rejectFriendSuccess, id });
const rejectFriendFailure = (id, error) => ({ type: types.rejectFriendsFailure, id, error });
const retreatFriendRequest = id => ({ type: types.retreatFriendRequest, id });
const retreatFriendRequestSuccess = id => ({ type: types.retreatFriendRequestSuccess, id });
const retreatFriendRequestFailure = (id, error) => ({
  type: types.retreatFriendRequestFailure,
  id,
  error
});
const getRequests = () => ({ type: types.getRequests });
const getRequestsSuccess = requests => ({ type: types.getRequestsSuccess, requests });
const getRequestsFailure = error => ({ type: types.getRequestsFailure, error });
const removeFriend = id => ({ type: types.removeFriend, id });
const removeFriendSuccess = id => ({ type: types.removeFriendSuccess, id });
const removeFriendFailure = (id, error) => ({ type: types.removeFriendFailure, id, error });
const sendFriendRequest = id => ({ type: types.sendFriendRequest, id });
const sendFriendRequestSuccess = (id, data) => ({ type: types.sendFriendRequestSuccess, id, data });
const sendFriendRequestFailure = (id, error) => ({
  type: types.sendFriendRequestFailure,
  id,
  error
});
const getFriendsProducts = () => ({type: types.getFriendsProducts});
const getFriendsProductsSuccess = products => ({ type: types.getFriendsProductsSuccess, products });
const getFriendsProductsFailure = error => ({ type: types.getFriendsProductsFailure, error });
const addOpenFriendRequests = (id, price, count) => ({
  type: types.addOpenFriendRequests,
  id,
  price,
  count
});
const addOpenFriendRequestsSuccess = count => ({
  type: types.addOpenFriendRequestsSuccess,
  count
});
const addOpenFriendRequestsFailure = error => ({ type: types.addOpenFriendRequestsFailure, error });

export default {
  getFriends,
  getFriendsSuccess,
  getFriendsFailure,
  addFriend,
  addFriendSuccess,
  addFriendFailure,
  rejectFriend,
  rejectFriendSuccess,
  rejectFriendFailure,
  getRequests,
  getRequestsSuccess,
  getRequestsFailure,
  removeFriend,
  removeFriendSuccess,
  removeFriendFailure,
  sendFriendRequest,
  sendFriendRequestSuccess,
  sendFriendRequestFailure,
  retreatFriendRequest,
  retreatFriendRequestFailure,
  retreatFriendRequestSuccess,
  getFriendsProducts,
  getFriendsProductsSuccess,
  getFriendsProductsFailure,
  addOpenFriendRequests,
  addOpenFriendRequestsSuccess,
  addOpenFriendRequestsFailure
};
