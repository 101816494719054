import React from 'react';

const FlagIcon = ({
  flag = 'de',
  height = '17px',
  width = '22px',
  lowQuality = false,
  styleObject = {},
  ...props
}) => (
  <img
    {...props}
    style={styleObject}
    height={height}
    width={width}
    src={`//s.svgbox.net/flags-${lowQuality ? 'ld' : 'hd'}.svg?#${flag?.toLowerCase()}`}
    alt="flag"
  />
);

export default FlagIcon;
