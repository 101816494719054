import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { RoundedButton, Input } from './ThemedComponents';
import Icon from './Icon';
import StyleGuide from './StyleGuide';

const CustomInput = styled(Input)``;
/**
 * Date input Component with Icon
 */
const InputDate = ({
  error = false,
  onChange,
  isPassword,
  value,
  placeholder,
  errorPlaceholder,
  iconName,
  margin,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  return (
    <RoundedButton
      padding='0px 10px'
      margin={margin || '10px'}
      justifyContent='flex-start'
      backgroundColor={error ? StyleGuide.colors.error : theme.input}
    >
      <Icon name={iconName} color={StyleGuide.colors.primary} size={25} />
      <CustomInput
        type='date'
        value={value}
        placeholder={error ? errorPlaceholder : placeholder}
        onChange={onChange}
        margin='0px 10px'
        color={theme.inputFont}
        {...props}
      />
    </RoundedButton>
  );
}

export default InputDate;
