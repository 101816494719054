import { useState, useLayoutEffect, useCallback } from 'react';

function useDimensions() {
  const [node, setNode] = useState(null);
  const [dimensions, setDimensions] = useState({});

  const ref = useCallback(usedNode => setNode(usedNode));
  useLayoutEffect(() => {
    function update() {
      if (node) {
        const { top, right, bottom, left, width, height, x, y } = node.getBoundingClientRect();
        setDimensions({ top, right, bottom, left, width, height, x, y });
      }
    }
    window.addEventListener('resize', update);
    window.addEventListener('load', update);
    update();
    return () => {
      window.removeEventListener('resize', update);
      window.removeEventListener('load', update);
    };
  }, [node]);
  return [ref, dimensions];
}

export default useDimensions;
