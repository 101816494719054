import types from './types';

const login = ({ email, password, token, userId, origin }) => ({
  type: types.login,
  email,
  password,
  token,
  userId,
  origin,
});
const loginSuccess = (status) => ({ type: types.loginSuccess, status });
const loginFailure = ({ error, userId, email, origin }) => ({
  type: types.loginFailure,
  error,
  userId,
  email,
  origin,
});

const register = ({
  origin,
  email,
  password,
  token,
  userId,
  name,
  picture,
  gender,
  birthday,
  location,
  previewPath,
  locationId,
  leadPage,
}) => ({
  type: types.register,
  origin,
  email,
  password,
  token,
  userId,
  name,
  picture,
  gender,
  birthday,
  location,
  previewPath,
  locationId,
  leadPage,
});
const registerSuccess = (status) => ({ type: types.registerSuccess, status });
const registerFailure = (error) => ({ type: types.registerFailure, error });

const getLocations = (location) => ({ type: types.getLocations, location });
const getLocationsSuccess = (result) => ({ type: types.getLocationsSuccess, result });
const getLocationsFailure = (error) => ({ type: types.getLocationsFailure, error });

const setSessionToken = (token) => ({ type: types.setSessionToken, token });

const logout = () => ({ type: types.logout });

const requestPasswordReset = (email) => ({ type: types.requestPasswordReset, email });
const requestPasswordResetSuccess = () => ({ type: types.requestPasswordResetSuccess });
const requestPasswordResetFailure = (error) => ({ type: types.requestPasswordResetFailure, error });

const verifyMail = (hash) => ({ type: types.verifyMail, hash });
const deleteAccount = (reason, message) => ({ type: types.deleteAccount, reason, message });
const deleteAccountSuccess = () => ({ type: types.deleteAccountSuccess });
const deleteAccountFailure = (error) => ({ type: types.deleteAccountFailure, error });

export default {
  getLocations,
  getLocationsSuccess,
  getLocationsFailure,
  setSessionToken,
  logout,
  requestPasswordReset,
  requestPasswordResetSuccess,
  requestPasswordResetFailure,
  verifyMail,
  deleteAccount,
  deleteAccountSuccess,
  deleteAccountFailure,
  login,
  loginSuccess,
  loginFailure,
  register,
  registerSuccess,
  registerFailure,
};
