// import { uploadFile } from 'react-s3';
import S3 from 'aws-s3';
import Config from '../config';

const S3Client = new S3(Config.s3);

const uploadImage = ({ file, fileName: newFileName }) =>
    S3Client.uploadFile(file, newFileName).then(({ status, body }) => {
        if (status === 201 || status === 204)
            console.log("SUCCESS");
        else
            throw new Error('Could not upload Image');

    }).catch(error => {
        console.log('err', error);
    });

export default uploadImage;