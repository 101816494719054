import { useState, useRef, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

function useMeasure() {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const ro = useRef(new ResizeObserver(([entry]) => setDimensions(entry.contentRect)));
  useEffect(() => {
    ro.current.observe(ref.current);
    return () => ro.current.disconnect();
  }, []);
  return [ref, dimensions];
}

export default useMeasure;
