import types from './types';

const getGallery = id => ({ type: types.getGallery, id });
const getGallerySuccess = (images, moreRecords) => ({
  type: types.getGallerySuccess,
  images,
  moreRecords,
});
const getGalleryFailure = error => ({ type: types.getGalleryFailure, error });
const loadMore = (id, page) => ({ type: types.loadMore, id, page });
const loadMoreSuccess = (images, moreRecords) => ({
  type: types.loadMoreSuccess,
  images,
  moreRecords,
});
const loadMoreFailure = error => ({ type: types.loadMoreFailure, error });
const setProfilePicture = id => ({ type: types.setProfilePicture, id });
const setProfilePictureSuccess = id => ({ type: types.setProfilePictureSuccess, id });
const setProfilePictureFailure = error => ({ type: types.setProfilePictureFailure, error });
const uploadImages = (images, visibility, price) => ({
  type: types.uploadImages,
  images,
  visibility,
  price,
});
const uploadImagesSuccess = images => ({ type: types.uploadImagesSuccess, images });
const uploadImagesFailure = error => ({ type: types.uploadImagesFailure, error });
const deleteImage = id => ({ type: types.deleteImage, id });
const deleteImageSuccess = id => ({ type: types.deleteImageSuccess, id });
const deleteImageFailure = error => ({ type: types.deleteImageFailure, error });
const changeVisibility = (id, visibility, price) => ({
  type: types.changeVisibility,
  id,
  visibility,
  price,
});
const changeVisibilitySuccess = (id, visibility, price) => ({
  type: types.changeVisibilitySuccess,
  id,
  visibility,
  price,
});
const changeVisibilityFailure = error => ({
  type: types.changeVisibilityFailure,
  error,
});

const setPictureAsReported = id => ({ type: types.setPictureAsReported, id });
const updateImage = (id, image) => ({ type: types.updateImage, id, image });


const uploadImageV2 = (image, imagePath, visibility, price, isProfile = 0 ) => ({
  type: types.uploadImageV2,
  imagePath,
  image,
  visibility,
  price,
  isProfile
});
const uploadImageV2Success = images => ({ type: types.uploadImageV2Success, images });
const uploadImageV2Failure = error => ({ type: types.uploadImageV2Failure, error });

export default {
  getGallery,
  getGallerySuccess,
  getGalleryFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  setProfilePicture,
  setProfilePictureSuccess,
  setProfilePictureFailure,
  uploadImages,
  uploadImagesSuccess,
  uploadImagesFailure,
  deleteImage,
  deleteImageSuccess,
  deleteImageFailure,
  changeVisibility,
  changeVisibilitySuccess,
  changeVisibilityFailure,
  setPictureAsReported,
  updateImage,
  uploadImageV2,
  uploadImageV2Success,
  uploadImageV2Failure
};
