import React from 'react';

import styled, { keyframes } from 'styled-components';
import StyleGuide from './StyleGuide';

const Button = styled.div`
  cursor: pointer;
  background-image: ${({theme:{background}}) => background};
  color: ${StyleGuide.colors.fontOnPrimary};
  border-radius: 10px;
  ${StyleGuide.boxShadowButton}
  height: 50px;
  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const knightRiderFrames = keyframes`
  from {
    transform: translateX(-100%);
  }
  82% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

const KnightRider = styled.div`
  background: linear-gradient(
    110deg,
    rgba(238, 238, 238, 0) 0%,
    rgba(229, 229, 229, 0) 40%,
    rgba(225, 225, 225, 0.8) 55%,
    rgba(226, 226, 226, 0) 61%,
    rgba(229, 229, 229, 0) 100%
  );
  height: 100%;
  opacity: 0.8;
  width: 100%;
  will-change: transform;
  animation: ${knightRiderFrames} 6s linear infinite;
`;
/**
 * Epic Animated KnightRider Button  
 */
const RegisterButton = ({ children, ...props }) => (
  <Button {...props}>
    <KnightRider />
    <Content>{children}</Content>
  </Button>
);

export default RegisterButton;
