import { keyBy, omit, uniq, mapValues } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import profileTypes from '../profile/types';
import userInformationTypes from '../userInformation/types';
import  loginTypes  from '../login/types';

const galleryReducer = (state = { byId: {}, allIds: [] }, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallerySuccess: {
      const { images } = action;
      // return { byId: keyBy(images, 'id'), allIds: images.map(({ id }) => id) };
      return {
        byId: { ...state.byId, ...keyBy(images, 'id') },
        allIds: uniq([...state.allIds, ...images.map(({ id }) => id)]),
      };
    }
    
    case loginTypes.logout: {
      return {
        byId: {},
        allIds: []
      }
    }
    case profileTypes.updateLeadFinishSuccess:
    case types.uploadImagesSuccess:
    case types.uploadImageV2Success:
    case types.loadMoreSuccess: {
      const { images = [] } = action;
      const { byId, allIds } = state;
      return {
        byId: { ...byId, ...keyBy(images, 'id') },
        allIds: uniq([...allIds, ...images.map(({ id }) => id)]),
      };
    }
    case types.deleteImageSuccess: {
      const { id } = action;
      const { byId, allIds } = state;
      return { byId: omit(byId, id), allIds: allIds.filter((item) => item !== id) };
    }
    case types.changeVisibilitySuccess: {
      const { id } = action;
      const { byId, allIds } = state;
      return { allIds, byId: { ...byId, [id]: imageReducer(byId[id], action) } };
    }
    case types.updateImage:
    case types.setProfilePictureSuccess: {
      const { id } = action;
      const { byId, allIds } = state;
      return {
        allIds,
        byId: {
          ...mapValues(byId, (item) => ({ ...item, isProfile: false })),
          [id]: imageReducer(byId[id], action),
        },
      };
    }
    case userInformationTypes.updateUserInformation: {
      const {
        image
      } = action.information;
      const { byId, allIds } = state;
      if (image?.id) {
        return {
          allIds: uniq([...allIds, image.id]),
          byId: {
            ...byId,
            [image.id]: {
              id: image.id,
              image: { uri: image.uri },
              isBillable: false,
              isProfile: true,
              locked: false,
              onlyForFriends: false,
              price: undefined,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const imageReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.changeVisibilitySuccess: {
      const { visibility, price } = action;

      return {
        ...state,
        onlyForFriends: visibility === 'friends',
        isBillable: visibility === 'locked',
        price,
      };
    }
    case types.setProfilePictureSuccess:
      return {
        ...state,
        isProfile: true,
        onlyForFriends: false,
        isBillable: false,
        price: 0,
      };
    case types.updateImage: {
      return { ...state, ...action.image };
    }
    default:
      return state;
  }
};

const nextPageReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallerySuccess:
      return 2;
    case types.loadMoreSuccess:
      return state + 1;
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallery:
    case types.uploadImages:
    case types.uploadImageV2:
      return true;
    case types.getGallerySuccess:
    case types.getGalleryFailure:
    case types.uploadImagesSuccess:
    case types.uploadImagesFailure:
    case types.uploadImageV2Success:
    case types.uploadImageV2Failure:
      return false;
    default:
      return state;
  }
};

const moreRecordsReducer = (state = true, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallerySuccess:
    case types.loadMoreSuccess: {
      const { moreRecords } = action;
      return moreRecords;
    }
    default:
      return state;
  }
};

const editSendingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.changeVisibility:
      return true;
    case types.changeVisibilityFailure:
    case types.changeVisibilitySuccess:
      return false;
    default:
      return state;
  }
};

const uploadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.uploadImageV2:
      return true;
    case types.uploadImageV2Success:
    case types.uploadImageV2Failure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  images: galleryReducer,
  page: nextPageReducer,
  isLoading: loadingReducer,
  moreRecords: moreRecordsReducer,
  isEditing: editSendingReducer,
  isUploading: uploadingReducer
});
