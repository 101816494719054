import types from './types';

const removeGallery = galleryId => ({ type: types.removeGallery, galleryId });
const addItems = items => ({ type: types.addItems, items });
const unlockItem = (id, value) => ({
  type: types.unlockItem,
  id,
  value,
});
const unlockItemSuccess = (id, values) => ({ type: types.unlockItemSuccess, id, values });
const unlockItemFailure = (id, error) => ({ type: types.unlockItemFailure, id, error });

export default {
  removeGallery,
  addItems,
  unlockItem,
  unlockItemSuccess,
  unlockItemFailure,
};
