const getLocations = 'app/login/GETLOCATIONS';
const getLocationsSuccess = 'app/login/GETLOCATIONSSUCCESS';
const getLocationsFailure = 'app/login/GETLOCATIONSFAILURE';

const setSessionToken = 'app/login/SETSESSIONTOKEN';

const logout = 'app/login/LOGOUT';

const requestPasswordReset = 'app/login/REQUESTPASSWORDRESET';
const requestPasswordResetSuccess = 'app/login/REQUESTPASSWORDRESETSUCCESS';
const requestPasswordResetFailure = 'app/login/REQUESTPASSWORDRESETFAILURE';

const verifyMail = 'app/login/VERIFYMAIL';
const deleteAccount = 'app/login/DELETEACCOUNT';
const deleteAccountSuccess = 'app/login/DELETEACCOUNTSUCCESS';
const deleteAccountFailure = 'app/login/DELETEACCOUNTFAILURE';

const login = 'app/login/LOGIN';
const loginSuccess = 'app/login/LOGINSUCCESS';
const loginFailure = 'app/login/LOGINFAILURE';

const register = 'app/login/REGISTER';
const registerSuccess = 'app/login/REGISTERSUCCESS';
const registerFailure = 'app/login/REGISTERFAILURE';

export default {
  getLocations,
  getLocationsSuccess,
  getLocationsFailure,
  setSessionToken,
  logout,
  requestPasswordReset,
  requestPasswordResetSuccess,
  requestPasswordResetFailure,
  verifyMail,
  deleteAccount,
  deleteAccountSuccess,
  deleteAccountFailure,
  login,
  loginSuccess,
  loginFailure,
  register,
  registerSuccess,
  registerFailure
};
