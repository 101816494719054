import { takeLatest, call } from 'redux-saga/effects';
import { SetToken, RemoveToken } from '../services/webAppEndPoint';
import types from './types';
import { userInformationTypes } from '../userInformation';

function* setTokenInStorage({ information }) {
  try {
    const { accessToken: token } = information;
    if (token)
      yield call(SetToken, token);
  } catch (err) {
    console.warn("SetToken Error", err);
  }
}

function* handleSetToken() {
  yield takeLatest(userInformationTypes.updateUserInformation, setTokenInStorage);
}

function* removeTokenFromStorage() {
  yield call(RemoveToken);
}

function* handleRemoveToken() {
  yield takeLatest(types.removeToken, removeTokenFromStorage);
}

export default { handleSetToken, handleRemoveToken };
