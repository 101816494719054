import { put, call, takeLeading, takeLatest, takeEvery, select } from 'redux-saga/effects';
import uuid from 'uuid/v4';
import types from './types';
import actions from './actions';
import {
  GetGalleryImages,
  uploadImages,
  deleteImage,
  updateImage,
  uploadImagesV2,
} from '../services/webAppEndPoint';
import uploadImage from '../services/s3EndPoint';
import { profileActions, profileSelectors } from '../profile';

const perPage = 40;

function* getMyGallery({ id }) {
  try {
    const { images: profileImages } = yield call(GetGalleryImages, id, null, 10, true);
    const { images, moreRecords } = yield call(GetGalleryImages, id, null, perPage);
    yield put(actions.getGallerySuccess([...profileImages, ...images], moreRecords));
  } catch (error) {
    yield put(actions.getGalleryFailure(error));
  }
}

function* handleGetGallery() {
  yield takeLatest(types.getGallery, getMyGallery);
}

function* loadMore({ id, page }) {
  try {
    const { images, moreRecords } = yield call(GetGalleryImages, id, page, perPage);
    yield put(actions.loadMoreSuccess(images, moreRecords));
  } catch (error) {
    yield put(actions.loadMoreFailure(error));
  }
}

function* handleLoadMore() {
  yield takeLatest(types.loadMore, loadMore);
}

function* upload({ images, price, visibility }) {
  try {
    const newImages = yield call(uploadImages, images, price, visibility);
    yield put(actions.uploadImagesSuccess(newImages));
  } catch (error) {
    yield put(actions.uploadImagesFailure(error));
  }
}

function* handleUpload() {
  yield takeLeading(types.uploadImages, upload);
}

function* uploadV2({ image, price, visibility, imagePath, isProfile }) {
  const guid = uuid();
  const filename = `${guid}.${image.type.split('/')[1]}`;

  yield call(uploadImage, { file: image, fileName: guid });

  const myId = yield select(profileSelectors.getProfileId);
  try {
    const newImages = yield call(uploadImagesV2, {
      filename,
      cost: price || 0,
      context: visibility,
      mimeType: image.type,
      userGuid: myId,
      guid,
      galleryId: myId,
      isProfilePic: isProfile,
    });
    const newImage = [{ ...newImages[0], image: { uri: imagePath } }];
    if (isProfile === 1) {
      yield put(profileActions.setProfilePic(imagePath));
    }
    yield put(actions.uploadImageV2Success(newImage));
  } catch (error) {
    yield put(actions.uploadImageV2Failure(error));
  }
}

function* handleUploadV2() {
  yield takeLeading(types.uploadImageV2, uploadV2);
}

function* deleteImg({ id }) {
  try {
    yield call(deleteImage, id);
    yield put(actions.deleteImageSuccess(id));
  } catch (error) {
    yield put(actions.deleteImageFailure(error));
  }
}

function* handleDelete() {
  yield takeEvery(types.deleteImage, deleteImg);
}

function* setPictureAsReported({ id }) {
  try {
    yield call(updateImage, { id, is_reported: 1 });
    yield put(profileActions.getProfile());
  } catch (error) {
    yield put(actions.setProfilePictureFailure(error));
  }
}
function* handleSetPictureAsReported() {
  yield takeLatest(types.setPictureAsReported, setPictureAsReported);
}

function* setProfilePicture({ id }) {
  try {
    yield call(updateImage, { id, isProfile: 1 });
    yield put(actions.setProfilePictureSuccess(id));
    yield put(profileActions.getProfile());
  } catch (error) {
    yield put(actions.setProfilePictureFailure(error));
  }
}

function* handleSetProfilePicture() {
  yield takeLatest(types.setProfilePicture, setProfilePicture);
}

function* changeVisibility({ id, visibility, price }) {
  try {
    yield call(updateImage, { id, visible: visibility, cost: price });
    yield put(actions.changeVisibilitySuccess(id, visibility, price));
  } catch (error) {
    yield put(actions.changeVisibilityFailure(error));
  }
}

function* handleChangeVisibility() {
  yield takeEvery(types.changeVisibility, changeVisibility);
}

export default {
  handleGetGallery,
  handleLoadMore,
  handleUpload,
  handleDelete,
  handleSetProfilePicture,
  handleChangeVisibility,
  handleSetPictureAsReported,
  handleUploadV2,
};
