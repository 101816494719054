import types from './types';
import { userInformationTypes } from '../userInformation';

const reducer = (state = null, { type, ...actionParameter }) => {
  switch (type) {
    case types.setToken:
      return actionParameter.token;
    case types.removeToken:
      return null;
    case userInformationTypes.updateUserInformation: {
      const { information } = actionParameter;
      if (information.accessToken)
        return information.accessToken;
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
