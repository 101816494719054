import {useRef, useCallback} from 'react';
import { GetLocationPredictions } from '../services/webAppEndPoint';

async function getPredictions(stub, countryCode) {
    if (stub.length < 3) {
      return [];
    }
  
    const predictions = await GetLocationPredictions(stub, countryCode);
    return predictions;
  }
  
  const useLocationPrediction = () => {
    const results = useRef(new Map());
    const getPredictionFromStub = useCallback(async (stub, countryCode = 'de') => {
      if (results.current.has(stub)) {
        return results.current.get(stub);
      }
  
      try {
        const result = await getPredictions(stub, countryCode);
        results.current.set(stub, result);
        return result;
      } catch (error) {
        console.log('GetPredictionError', error);
        return [];
      }
    }, []);
    return getPredictionFromStub;
  };

  export default useLocationPrediction;