import { combineReducers } from 'redux';
import types from './types';
import userInformationTypes from '../userInformation/types';
import loginTypes from '../login/types';
import { myGalleryTypes } from '../myGallery';

const profileReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.setProfile:
      return action.profile;
    case userInformationTypes.updateUserInformation: {
      const { information } = action;
      if (information.profile !== undefined)
        return information.profile;
      return state;
    }
    case types.updateProfile:
      return { ...state, ...action.profile };
    case types.updateLeadSuccess:
      return { ...state, image: { uri: action.currentPreview } };
    case userInformationTypes.getUserFeedSuccess: {
      if (action.info.hasNoProfilePicture) {
        return { ...state, image: null };
      }
      return state;
    }
    case types.setProfilePic: {
      const { path } = action;
      if (path)
        return { ...state, image: { uri: path } };
      return state;
    }
    case types.updateLocationSuccess: {
      const { cityName } = action;
      return { ...state, city: cityName };
    }
    default:
      return state;
  }
};

const editReducer = (state = false, { type }) => {
  switch (type) {
    case types.setEdit:
      return true;
    case types.saveProfile:
      return false;
    default:
      return state;
  }
};

const savingReducer = (state = false, { type }) => {
  switch (type) {
    case types.saveProfile:
      return true;
    case types.saveProfileFailure:
    case types.saveProfileSuccess:
      return false;
    default:
      return state;
  }
};

const emailReminderReducer = (state = null, action) => {
  switch (action.type) {
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.isMailVerified !== undefined)
        return info.isMailVerified;
      return state;

    } default:
      return state;
  }
};

const dailyCoinsReducer = (state = false, { type, coins }) => {
  switch (type) {
    case types.setDailyCoins:
      return coins;
    default:
      return state;
  }
};

const downloadedAppReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case userInformationTypes.updateUserInformation: {
      const { information } = action;
      if (information.hasDownloadedApp !== undefined)
        return information.hasDownloadedApp;
      return state;
    }
    default:
      return state;
  }
};

const statusReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case userInformationTypes.updateUserInformation: {
      const { information } = action;
      if (information.status !== undefined)
        return information.status;
      return state;
    }
    case types.updateLeadSuccess:
    case types.updateLeadFinishSuccess:
      return 'active';
    case types.updateLeadFinishFailure:
      return 'aff_incomplete';
    default:
      return state;
  }
};

const friendsCountReducer = (state = 0, action) => {
  switch (action.type) {
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.friends !== undefined)
        return info.friends;
      return state;
    }
    default:
      return state;
  }
};

const presentsCountReducer = (state = 0, action) => {
  switch (action.type) {
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.presents !== undefined)
        return info.presents;
      return state;
    }
    default:
      return state;
  }
};

const profileNoticeReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.setModalNotice:
      return action.notice;
    case userInformationTypes.updateUserInformation: {
      const { information } = action;
      if (information.notice !== undefined)
        return information.notice;
      return state;
    }
    default: {
      return state;
    }
  }
};

const sendEmailReducer = (state = false, action) => {
  switch (action.type) {
    case types.sendVerifyMail:
    case types.changeEmail:
      return true;

    case types.sendVerifyMailSuccess:
    case types.changeEmailSuccess:
      return false;
    default:
      return state;
  }
};
const loadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.updateLead:
    case loginTypes.deleteAccount:
    case types.updateLocation:
      return true;
    case types.updateLeadSuccess:
    case types.updateLeadFailure:
    case loginTypes.deleteAccountSuccess:
    case loginTypes.deleteAccountFailure:
    case types.updateLocationSuccess:
    case types.updateLocationFailure:
      return false;
    default:
      return state;
  }
};

const statementReducer = (state = 0, action) => {
  const { statement } = action;
  switch (action.type) {
    case types.setStatementSuccess:
      return statement;
    default:
      return state;
  }
};

const isSubscribed = (state = false, action) => {
  switch (action.type) {
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.hasActiveSubscription !== undefined)
        return !!info.hasActiveSubscription;
      return state;
    }
    default:
      return state;
  }
};

const welcomeModalReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    // case loginTypes.registerSuccess:
    //   return true;
    case types.shownRegisterModal:
      return false;
    default:
      return state;
  }
};

const changeLocationReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.canChangeLocation !== undefined)
        return !!info.canChangeLocation;
    }
      return state;
    case types.updateLocationSuccess:
      return false;
    default:
      return state;
  }
};

const noPictureReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case userInformationTypes.getUserFeedSuccess: {
      if (action.info.hasNoProfilePicture !== undefined) {
        return !!action.info.hasNoProfilePicture;
      }
      return state;
    }
    case myGalleryTypes.uploadImageV2Success:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data: profileReducer,
  edit: editReducer,
  isSaving: savingReducer,
  isVerified: emailReminderReducer,
  showDailyCoins: dailyCoinsReducer,
  profileNotice: profileNoticeReducer,
  sendEmail: sendEmailReducer,
  downloadedApp: downloadedAppReducer,
  status: statusReducer,
  counts: combineReducers({
    presents: presentsCountReducer,
    friends: friendsCountReducer
  }),
  isLeadLoading: loadingReducer,
  statement: statementReducer,
  isSubscribed,
  showWelcomeModal: welcomeModalReducer,
  hasNoPicture: noPictureReducer,
  canChangeLocation: changeLocationReducer
});
