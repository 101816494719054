import { put, call, take } from 'redux-saga/effects';
import types from './types';
import { getUserFeed } from '../services/webAppEndPoint';
import { loginTypes } from '../login';
import { profileTypes } from '../profile';
import actions from './actions';
import { takeTimed } from '../sagas';

function* getShortTermInfo() {
  try {
    const info = yield call(getUserFeed, ['mailverified', 'coins', 'freecredits', 'activeAbo']);
    yield put(actions.getUserFeedSuccess(info));
  } catch (error) {
    yield put(actions.getUserFeedFailure(error))
  }
}

function* getMidTermInfo() {
  try {
    const info = yield call(getUserFeed, ['newfriends', 'newlikes', 'newgifts', 'newvisitors', 'swipes', 'friendrequests', 'mediaReports']);
    yield put(actions.getUserFeedSuccess(info));
  } catch (error) {
    yield put(actions.getUserFeedFailure(error))
  }
}

function* getLongTermInfo() {
  try {
    const info = yield call(getUserFeed, ['countfriends', 'countpresents', 'favoriteLeft', 'canChangeLocation']);
    yield put(actions.getUserFeedSuccess(info));
  } catch (error) {
    yield put(actions.getUserFeedFailure(error))
  }
}

function* handleGetShortTermInfo() {
  yield takeTimed(types.getUserFeed, getShortTermInfo, 300);
}

function* handleGetMidTermInfo() {
  yield takeTimed(types.getUserFeed, getMidTermInfo, 3600);
}

function* handleGetLongTermInfo() {
  yield takeTimed(types.getUserFeed, getLongTermInfo, 86400);
}

function* handleGetModifiedValues() {
  const { type } = yield take([
    types.getUserFeed,
    loginTypes.loginSuccess,
    loginTypes.registerSuccess
  ]);
  if (type !== types.getUserFeed) {
    yield put(actions.getUserFeed());
  }
}

export default {
  handleGetModifiedValues,
  handleGetShortTermInfo,
  handleGetMidTermInfo,
  handleGetLongTermInfo
};
