import { takeLatest, call, put, select, take, takeLeading } from 'redux-saga/effects';
import Router from 'next/router';
import types from './types';
import actions from './actions';
import {
  UpdateProfile,
  UpdateUser,
  ChangeUserLocation,
  GetOwnProfile,
  SendVerifyEmail,
  ChangeUserEmail,
  UpdateLeadProfile,
  UpdateLeadStatus,
  uploadImages,
  SetStatement
} from '../services/webAppEndPoint';
import { userInformationActions } from '../userInformation';
import { geocodeByPlaceId } from '../login/sagas';
import { paymentFunctions } from '../payment';

function* saveProfile({ profile: profileInformation }) {
  try {
    yield call(UpdateProfile, profileInformation);
    yield put(actions.saveProfileSuccess(profileInformation));
  } catch (exception) {
    yield put(actions.saveProfileFailure(exception));
  }
}

function* handleSaveProfile() {
  yield takeLatest(types.saveProfile, saveProfile);
}

function* updateUser({ afId }) {
  try {
    yield call(UpdateUser, {appsflyer_id: afId});
  } catch (exception) {
    console.log("err update afid", exception);
  }
}

function* handleUpdateUser() {
  yield takeLatest(types.updateUser, updateUser);
}

function* getProfile() {
  try {
    const information = yield call(GetOwnProfile);
    yield put(userInformationActions.updateInformation(information));
  } catch (error) {
    yield put(actions.getProfileFailure(error));
  }
}

function* handleGetProfile() {
  yield takeLatest([types.getProfile, types.updateLeadFinishSuccess], getProfile);
}

function* sendVerifyMail() {
  try {
    yield call(SendVerifyEmail);
    yield put(actions.sendVerifyMailSuccess());
  } catch (error) {
    yield put(actions.sendVerifyMailFailure(error));
  }
}

function* handleSendVerifyMail() {
  yield takeLeading(types.sendVerifyMail, sendVerifyMail);
}

function* changeEmail({ newEmail }) {
  try {
    yield call(ChangeUserEmail, newEmail);
    yield call(SendVerifyEmail);
    yield put(actions.changeEmailSuccess(newEmail));
  } catch (error) {
    yield put(actions.changeEmailFailure(error));
  }
}

function* handleChangeEmail() {
  yield takeLatest(types.changeEmail, changeEmail);
}

export function* showNoticeModal() {
  while (true) {
    const { notice } = yield take(types.setModalNotice);
    if (notice) {
      const { type } = yield take([types.acceptModalAlert, types.acceptModalAlert]);
      yield put(actions.setModalNotice());
      if (type === types.declineModalAlert) {
        throw new Error('abort');
      }
    }
  }
}

function* updateLead({ fileList, gender, birthday, geoId, currentPreview }) {
  try {
    let location = geoId || {};
    if (geoId && geoId.place_id) {
      const sessionToken = yield select(({ login: { sessionToken: token } }) => token);
      location = yield call(geocodeByPlaceId, geoId.place_id);
    }

    const { latitude, longitude, city, country } = location;
    yield call(UpdateLeadProfile, {
      gender,
      birthday,
      latitude,
      longitude,
      city,
      country,
    });
    yield put(actions.updateLeadSuccess(fileList, currentPreview));
  } catch (error) {
    yield put(actions.updateLeadFailure(error));
  }
}

function* handleUpdateLead() {
  yield takeLatest(types.updateLead, updateLead);
}

function* updateLeadFinish({ fileList }) {
  try {
    const newImages = yield call(uploadImages, [fileList], 0, 'open', 1);
    yield call(UpdateLeadStatus);
    yield put(actions.updateLeadFinishSuccess(newImages));
  } catch (error) {
    yield put(actions.updateLeadFinishFailure(error));
  }
}

function* handleUpdateLeadSuccess() {
  yield takeLatest(types.updateLeadSuccess, updateLeadFinish);
}

function* setStatement(statement) {
  try {
    yield call(SetStatement, statement);
    yield put(actions.setStatementSuccess(statement));
  } catch (error) {
    yield put(actions.setStatementFailure(error));
  }
}

function* handleSetStatement() {
  yield takeLatest(types.setStatement, setStatement);
}

function* setLocation({ id, cityName, price, isFirst }) {
  try {
    yield call(ChangeUserLocation, id);
    if (!isFirst)
      yield* paymentFunctions.finishTransaction(price);
    yield put(actions.updateLocationSuccess(cityName));
  } catch (error) {
    yield put(actions.updateLocationFailure(error));
  }
}

function* handleSetLocation() {
  yield takeLatest(types.updateLocation, setLocation);
}


export default {
  handleSaveProfile,
  handleGetProfile,
  showNoticeModal,
  handleChangeEmail,
  handleSendVerifyMail,
  handleUpdateLead,
  handleUpdateLeadSuccess,
  handleSetStatement,
  handleSetLocation,
  handleUpdateUser
};
