import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const Container = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${props => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 999;
  ${({ style }) => style};
`;
/**
 *  Custom Modal added to ReactDOM
 */
const Modal = ({ show, children, animation = null, animated = true }) => {
  const element = document.querySelector('#__next');
  const style =
    animation ||
    useSpring({
      from: { transform: 'scale(0)' },
      to: { transform: show ? 'scale(1)' : 'scale(0)' },
    });
  return ReactDOM.createPortal(
    <Container show={show} role="presentation" style={animated ? style : undefined}>
      {children}
    </Container>,
    element
  );
};

export default Modal;
