import { take, fork } from 'redux-saga/effects';
import { pickBy } from 'lodash';

function* takeUniq(
  type,
  saga,
  keyFunction
) {
  let tasks = {};
  while (true) {
    const action = yield take(type);
    const key = keyFunction(action);
    if (!tasks[key] || !tasks[key].isRunning()) {
      const task = yield fork(saga, action);
      tasks[key] = task;
    }
    tasks = pickBy(tasks, (value) => value.isRunning());
  }
}

export default takeUniq;