const getGallery = 'app/user/gallery/GETGALLERY';
const getGallerySuccess = 'app/user/gallery/GETGALLERYSUCCESS';
const getGalleryFailure = 'app/user/gallery/GETGALLERYFAILURE';
const loadMore = 'app/user/gallery/LOADMORE';
const loadMoreSuccess = 'app/user/gallery/LOADMORESUCESS';
const loadMoreFailure = 'app/user/gallery/LOADMOREFAILURE';
const setProfilePicture = 'app/user/gallery/SETPROFILEPICTURE';
const setProfilePictureSuccess = 'app/user/gallery/SETPROFILEPICTURESUCCESS';
const setProfilePictureFailure = 'app/user/gallery/SETPROFILEPUCTUREFAILURE';
const uploadImages = 'app/user/gallery/UPLOADIMAGES';
const uploadImagesSuccess = 'app/user/gallery/UPLOADIMAGESSUCCESS';
const uploadImagesFailure = 'app/user/gallery/UPLOADIMAGESFAILURE';
const uploadImageV2 = 'app/user/gallery/UPLOADIMAGEV2';
const uploadImageV2Success = 'app/user/gallery/UPLOADIMAGEV2SUCCESS';
const uploadImageV2Failure = 'app/user/gallery/UPLOADIMAGEV2FAILURE';
const deleteImage = 'app/user/gallery/DELETEIMAGES';
const deleteImageSuccess = 'app/user/gallery/DELETEIMAGESSUCCESS';
const deleteImageFailure = 'app/user/gallery/DELETEIMAGEFAILURE';
const changeVisibility = 'app/user/gallery/CHANGEVISIBILITY';
const changeVisibilitySuccess = 'app/user/gallery/CHANGEVISIBILITYSUCCESS';
const changeVisibilityFailure = 'app/user/gallery/CHANGEVISIBILITYFAILURE';
const setPictureAsReported = 'app/user/gallery/SETPICTUREASREPORTED';
const updateImage = 'app/user/gallery/UPDATE';

export default {
  getGallery,
  getGallerySuccess,
  getGalleryFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  setProfilePicture,
  setProfilePictureSuccess,
  setProfilePictureFailure,
  uploadImages,
  uploadImagesSuccess,
  uploadImagesFailure,
  deleteImage,
  deleteImageSuccess,
  deleteImageFailure,
  changeVisibility,
  changeVisibilitySuccess,
  changeVisibilityFailure,
  setPictureAsReported,
  updateImage,
  uploadImageV2,
  uploadImageV2Success,
  uploadImageV2Failure
};
