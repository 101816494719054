const themeLight = {
  main: '#507FFF',
  mainVariantLight: '#66ACFF',
  mainVariantDark: '#0054B7',
  mainVariantUltraLight: '#D1E7F9',
  backgroundPrimary: '#fbfbfb',
  backgroundSecondary: '#ffffff',
  backgroundFooterLight: 'ffffff',
  backgroundFooterDark: '#696969',
  fontOnPrimary: '#000',
  fontOnPrimaryLight: '#000',
  fontOnSecondary: '#fff',
  fontOnMain: '#fff',
  background: 'linear-gradient(180deg, #3ABAFF 0%, #1B84FF 100%)',
  noBackground:
    'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
  noBackgroundSecondary:
    'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
  marker: '#D82D2D',
  markerText: '#fff',
  input: '#fff',
  inputFont: '#000',
  inputIcon: '#507FFF',
  inputDark: '#EFF3F4',
  icon: '#507FFF',
  loaderLight: '#FFFFFF',
  loaderDark: '#F5F5F5',
  splitter: '#e6e6e6',
  chatInfo: '#D1E7F9',
  chatInfoText: '#000',
  tileFontPrimary: '#507FFF',
  tileFontSecondary: '#363738',
  blockOverlay: '#EFF3F4',
  blockOverlayFont: '#000',
  paymentOption: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
  externalLink: '#507FFF'
};

const themeDark = {
  main: '#507FFF',
  mainVariantLight: '#66ACFF',
  mainVariantDark: '#0054B7',
  mainVariantUltraLight: '#D1E7F9',
  backgroundPrimary: '#2E2F30',
  backgroundSecondary: '#363738',
  backgroundFooterLight: '#363738',
  backgroundFooterDark: '#2E2F30',
  fontOnPrimary: '#fff',
  fontOnPrimaryLight: '#fff',
  fontOnSecondary: '#fff',
  fontOnMain: '#fff',
  background: 'linear-gradient(180deg, #3ABAFF 0%, #1B84FF 100%)',
  noBackground:
    'linear-gradient(180deg, #2E2F30 0%, #2E2F30 100%)',
  noBackgroundSecondary:
    'linear-gradient(180deg, #363738 0%, #363738 100%)',
  marker: '#D82D2D',
  markerText: '#fff',
  input: '#4C4D4F',
  inputFont: '#fff',
  inputIcon: '#507FFF',
  inputDark: '#4C4D4F',
  icon: '#507FFF',
  loaderLight: '#4C4D4F',
  loaderDark: '#363738',
  splitter: '#4C4D4F',
  chatInfo: '#4C4D4F',
  chatInfoText: '#fff',
  tileFontPrimary: '#fff',
  tileFontSecondary: '#D3D3D3',
  blockOverlay: '#4C4D4F',
  blockOverlayFont: '#fff',
  paymentOption: 'linear-gradient(180deg, #4C4D4F 0%, #4C4D4F 100%)',
  externalLink: '#507FFF'
};

export default { themeLight, themeDark };
