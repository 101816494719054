import { useRef, useEffect } from 'react';

const useCompareWithPrevious = (value, callback, initialValue) => {
  const previousVal = useRef(initialValue);
  useEffect(() => {
    callback(previousVal.current);
    previousVal.current = value;
  }, [callback, value]);
};

export default useCompareWithPrevious;
