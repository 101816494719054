import { createSelector } from 'reselect';

const getGalleryItemsPart = state => state.galleryItems;
const getItemsById = state => getGalleryItemsPart(state).byId;
const getAllIds = state => getGalleryItemsPart(state).allIds;

// const getGalleryId = (_, { ownProps, galleryId }) => galleryId || ownProps.id;
const getGalleryId = (state, ownProps, { id }) => id;
// '6d6d9295-ee51-41d0-9245-30a6155afa61'

const getAllGalleryItems = createSelector(
  getAllIds,
  getItemsById,
  (ids, items) => ids.map(id => items[id]),
);

const makeGetAllItemsInGallery = () => createSelector(
  getAllGalleryItems,
  getGalleryId,
  (items, id) => items.reduce((arr, item) => (item.galleryId === id ? [...arr, item] : arr), []),
);

export default { getAllGalleryItems, makeGetAllItemsInGallery };
