import React, { memo } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { Text, DimBackground, ThemedText, Title } from './ThemedComponents';
import StyleGuide from './StyleGuide';
import Icon from './Icon';
import Modal from './Modal';
import { useTranslation } from '../i18n';

const Content = styled.div`
  display: flex;
  width: 350px;
  border-radius: 10px;
  background-color: ${({theme: {backgroundSecondary}}) => backgroundSecondary};
  justify-content: center;
  flex-direction: column;
  position: absolute;
  align-items: stretch;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 25px;
  padding-top: 10px;
  @media only screen and (max-width: 414px) {
    max-width:75%;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 10px;
`;
const Button = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 5px;
  margin: 5px 10px;
  cursor: ${({isDisabled}) => isDisabled ? 'initial' : 'pointer'};
  opacity: ${({isDisabled}) => isDisabled ? 0.5 : 1};
  border-radius: 25px;
  background: ${({ marked, theme: { background, backgroundSecondary } }) => marked ? (background) : backgroundSecondary};
  color: ${({ marked, theme: { main } }) => marked ? StyleGuide.colors.fontOnBackground : main}; 
    ${({ marked }) => marked ? StyleGuide.boxShadowHigh : null}
  min-width: max-content;
  transition: .15s;
  :hover{
    box-shadow: none;
  }
  @media only screen and (max-width: 414px) {
    width:75%;
  }
`;

const CloseButton = styled.div`
display:flex;
position: absolute;
top: 10px;
right: 10px;
justify-content: center;
align-items:center;
padding: 3px;
background: transparent;
cursor: pointer;
`;

const HeaderPicture = styled.img`
position: absolute;
height: 80px;
width: 80px;
top: -40px;
left: 50%;
transform: translateX(-50%);
`;

const ModalTitle = styled(Title)`
color: ${({theme: {fontOnPrimary}}) => fontOnPrimary};
margin: 15px 10px;
`;

const ModalText = styled(Text)`
color: ${({theme: {fontOnPrimary}}) => fontOnPrimary};
margin: 15px 10px;
`;

/**
 * Alert Modal with interaction 
 */
const CustomAlert = ({
  show = false,
  setShow,
  alertTitle,
  alertText,
  children,
  onDecline,
  onAccept,
  acceptText,
  isDisabled = false,
  declineText,
  singleButton,
  background,
  picName,
  closeable
}) => {
  const { t } = useTranslation('common');
  const props = useSpring({ opacity: show ? 1 : 0 });
  return (
    <Modal show={show} animated={false}>
      <DimBackground>
        <Content as={animated.div} style={props} bg={background}>
          {closeable && (
            <CloseButton onClick={() => setShow(false)}>
              <Icon name="decline" color="lightgrey" size={20} />
            </CloseButton>
          )
          }
          {picName && (
            <HeaderPicture src={`/static/images/ModalImages/${picName}.png`} alt="pic" />
          )}
          {alertTitle && <ModalTitle>{alertTitle}</ModalTitle>}
          {alertText && <ModalText style={{ marginTop: alertTitle ? '5px' : '25px' }}>{alertText}</ModalText>}

          {children}
          {singleButton ? (
            <ButtonContainer>
              <Button isDisabled={isDisabled} onClick={isDisabled ? undefined : () => onAccept()} marked>
                <Text>{acceptText || t('understood')}</Text>
              </Button>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button isDisabled={isDisabled} onClick={isDisabled ? undefined : () => onAccept()} marked>
                <Text style={{ fontWeight: 'bolder' }}>{acceptText || t('understood')}</Text>
              </Button>
              <Button onClick={onDecline ? () => onDecline() : () => setShow(false)}>
                <ThemedText style={{ fontWeight: 'bolder' }}>{declineText || t('decline')}</ThemedText>
              </Button>
            </ButtonContainer>
            )}
        </Content>
      </DimBackground>
    </Modal>
  );
};

export default memo(CustomAlert);
