import { connect } from 'react-redux';
import { profileActions } from '../profile';
import { friendsActions } from '../friends';
import { galleryItemsActions } from '../galleryItems';
import GalleryModal from './GalleryOverlay';

const mapStateToProps = state => ({
  canSendFriendRequest: state.friends.openFriendRequests > 0,
});

const mapDispatchToProps = {
  getProfile: id => profileActions.getProfile(id),
  unlockItem: (id, value) => galleryItemsActions.unlockItem(id, value),
  sendFriendRequest: id => friendsActions.sendFriendRequest(id),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GalleryModal);
