import types from './types';

const getProducts = () => ({ type: types.getProducts });
const getProductsSuccess = products => ({ type: types.getProductsSuccess, products });
const getProductsFailure = error => ({ type: types.getProductsFailure, error });
const addAmount = value => ({ type: types.addAmount, value });
const buyCoins = (product, actionName, screenName, paymentMethod) => ({
  type: types.buyCoins,
  product,
  actionName,
  screenName,
  paymentMethod,
});
const buyCoinsFailure = error => ({ type: types.buyCoinsFailure, error });
const removeAmount = value => ({ type: types.removeAmount, value });
const getPriceList = () => ({ type: types.getPriceList });
const getPriceListSuccess = priceList => ({ type: types.getPriceListSuccess, priceList });
const getPriceListFailure = error => ({ type: types.getPriceListFailure, error });
const setBuyAlert = visible => ({
  type: types.buyAlert,
  visible,
});
const setFinishedTransaction = isFinished => ({ type: types.setFinishedTransaction, isFinished });

export default {
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  getPriceList,
  getPriceListSuccess,
  getPriceListFailure,
  buyCoins,
  buyCoinsFailure,
  setBuyAlert,
  addAmount,
  removeAmount,
  setFinishedTransaction,
};
