const removeGallery = 'app/gallery/items/REMOVE';
const addItems = 'app/gallery/items/ADD';
const unlockItem = 'app/gallery/items/UNLOCK';
const unlockItemSuccess = 'app/gallery/items/UNLOCKSUCCESS';
const unlockItemFailure = 'app/gallery/items/UNLOCKFAILURE';

export default {
  removeGallery,
  addItems,
  unlockItem,
  unlockItemSuccess,
  unlockItemFailure,
};
