import { createStructuredSelector, createSelector } from 'reselect';
import { sortBy } from 'lodash';

const getPaymentPart = state => state.payment;
const getProducts = state => getPaymentPart(state).products;
const getCoins = state => getPaymentPart(state).coins;
const getActionName = state => getPaymentPart(state).actionName;
const getPriceList = state => getPaymentPart(state).priceList;
const getIsTransactionFinished = state => getPaymentPart(state).finishedTransaction;

const getSortedProducts = createSelector(
  getProducts,
  products => sortBy(products, 'count')
);

const makeGetPrice = item =>
  createSelector(
    getPriceList,
    priceList => priceList[item]
  );

const getPaymentConfig = createStructuredSelector({
  products: getSortedProducts,
});

export default {
  getPaymentConfig,
  getCoins,
  getActionName,
  makeGetPrice,
  getIsTransactionFinished,
  getPriceList
};
