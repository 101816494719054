import React, { memo } from 'react';
import styled from 'styled-components';
import StyleGuide from './StyleGuide';

const StyledIcon = styled.span.attrs(props => ({
  className: `icon-${props.name}`,
}))`
  font-size: ${props => props.size || '25'}px;
  color: ${props =>
    props.marked ? props.theme.main : props.color || StyleGuide.colors.fontOnPrimary};
  margin: ${props => props.margin || '5px'};
`;
/**
 * Icon component with icomoon svgs 
 */
const Icon = ({ name, size, color, marked, ...props }) => (
  <StyledIcon size={size} name={name} color={color} marked={marked} {...props} />
);

export default memo(Icon);
