import { useCallback, useMemo, useState } from 'react';
import { max, clamp } from 'lodash';
import { useDrag } from 'react-use-gesture';
import { useSpring } from 'react-spring';

function useGalleryScroll(itemLength, containerLength, arrayLength) {
  const [restingOffset, setRestingOffset] = useState(0);
  const maxOffset = useMemo(
    () => max([itemLength && containerLength ? arrayLength * itemLength - containerLength : 0, 0]),
    [itemLength, containerLength, arrayLength]
  );
  const [{ offset }, set] = useSpring(() => ({
    offset: 0,
    onRest: ({ offset: newOffset }) => setRestingOffset(newOffset),
  }));
  const move = useCallback(
    right => {
      const currentOffset = offset.getValue();
      const newOffset = clamp(
        right
          ? Math.floor((currentOffset + containerLength) / itemLength) * itemLength
          : currentOffset - Math.floor(containerLength / itemLength) * itemLength,
        0,
        maxOffset
      );
      set({ offset: newOffset });
    },
    [containerLength, itemLength, maxOffset]
  );
  const bind = useDrag(({ memo, movement: [moveX], vxvy: [vx], last }) => {
    const initialOffset = memo || offset.getValue();
    set({ offset: clamp(initialOffset - moveX, 0, maxOffset) });
    if (last && moveX > 0) {
      set({
        offset: clamp(
          (moveX < 0
            ? Math.ceil((initialOffset - moveX) / itemLength)
            : Math.floor((initialOffset - moveX) / itemLength)) * itemLength,
          0,
          maxOffset
        ),
        config: { velocity: vx },
      });
    }
    return initialOffset;
  });

  return [offset, { lower: restingOffset === 0, upper: restingOffset === maxOffset }, bind, move];
}

export default useGalleryScroll;
