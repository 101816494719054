import { combineReducers } from 'redux';
import types from './types';

const loginReducer = (state = null, { type, ...actionParameter }) => {
  switch (type) {
    case types.loginSuccess:
    case types.registerSuccess: {
      return actionParameter.status;
    }
    case types.loginFailure:
    case types.registerFailure: {
      if (actionParameter.error.statusCode) {
        return actionParameter.error.statusCode;
      }
      if (actionParameter.error.status_code) {
        return actionParameter.error.status_code;
      }
      return actionParameter.error;
    }
    case types.login:
    case types.register:
      return null;
    default:
      return state;
  }
};

const locationReducer = (state = null, { type, ...actionParameter }) => {
  switch (type) {
    case types.getLocations:
      return null;
    case types.getLocationsSuccess:
      return actionParameter.result;
    default:
      return state;
  }
};

const sessionTokenReducer = (state = null, action) => {
  if (action.type === types.setSessionToken) {
    return action.token;
  }
  return state;
};
const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case types.login:
      case types.register:
      return true;
    case types.loginSuccess:
    case types.loginFailure:
    case types.registerSuccess:
    case types.registerFailure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  status: loginReducer,
  location: locationReducer,
  sessionToken: sessionTokenReducer,
  isLoading: loadingReducer,
});
