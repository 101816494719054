import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { RoundedButton, Input, Text } from './ThemedComponents';
import Icon from './Icon';
import StyleGuide from './StyleGuide';

const Col = styled(RoundedButton)`
  position: relative;
  /* z-index: 500; */
`;

const PredictionContainer = styled.div`
  position: absolute;
  border: 1px solid ${StyleGuide.colors.primary};
  border-bottom: none;
  border-top: none;
  /* z-index: 859; */
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${({theme: {input}}) => input};
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  background-color: ${({theme: {input}}) => input};
  padding: 10px 0px;
  margin-top: -5px;
  display: ${props => (props.visible ? 'block' : 'none')};
  ${StyleGuide.boxShadowHigh};
  z-index: 1000;
`;
const Inpu = styled(Input)`
  width: 100%;
  color: ${({theme: {inputFont}}) => inputFont};
`;

const Prediction = styled.div`
  border-bottom: 1px solid ${StyleGuide.colors.primary};
  align-self: stretch;
  margin: 0px 30px;
  padding: 0px 30px;
`;
/**
 * Custom Text Input Component with prediction and Icon 
 */
const InputTextWithPrediction = ({
  error = false,
  onChange,
  isPassword,
  value,
  placeholder,
  errorPlaceholder,
  iconName,
  margin,
  predictions,
  predictionSubmit,
  ...props
}) => {
  const theme = useContext(ThemeContext);
return(
  <Col
    padding='0px 10px'
    margin={margin || '10px'}
    justifyContent='flex-start'
    backgroundColor={error ? StyleGuide.colors.error : theme.input}
  >
    <Icon name={iconName} color={theme.inputIcon} size={25} />
    <Inpu
      type={isPassword ? 'password' : 'text'}
      list='predList'
      value={value}
      placeholder={error ? errorPlaceholder : placeholder}
      onChange={onChange}
      margin='0px 10px'
      {...props}
    />
    <PredictionContainer id='predList' visible={predictions.length > 0}>
      {predictions.map(prediction => (
        <Prediction onClick={() => predictionSubmit(prediction)}>
          <Text color={theme.inputFont} textAlign='left'>
            {prediction.city}
          </Text>
        </Prediction>
        ))}
    </PredictionContainer>
  </Col>
  );}
export default InputTextWithPrediction;
