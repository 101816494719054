import { combineReducers } from 'redux';
import types from './types';
import { userInformationTypes } from '../userInformation';

const coinsReducer = (state = 0, { type, ...actionParameter }) => {
  switch (type) {
    case types.addAmount:
      return state + actionParameter.value;
    case types.removeAmount:
      return state - actionParameter.value;
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = actionParameter;
      if (info.coins !== undefined)
        return info.coins;
      return state;
    }
    default:
      return state;
  }
};

const productReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getProductsSuccess: {
      const { products } = action;
      return products;
    }
    default:
      return state;
  }
};

const priceListReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getPriceListSuccess: {
      const { priceList } = action;
      return priceList;
    }
    default:
      return state;
  }
};

const toggleReducer = toggleType => (state = false, action) => {
  const { type } = action;
  switch (type) {
    case toggleType: {
      const { value } = action;
      return value;
    }
    default:
      return state;
  }
};

const finishedTransactionReducer = (state = true, action) => {
  switch (action.type) {
    case types.setFinishedTransaction:
      return action.isFinished;
    default:
      return state;
  }
};

export default combineReducers({
  coins: coinsReducer,
  products: productReducer,
  priceList: priceListReducer,
  buyMoreCoins: toggleReducer(types.buyMoreCoins),
  finishedTransaction: finishedTransactionReducer,
});
