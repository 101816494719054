import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const cssProperty = '(prefers-color-scheme: dark)';

const useDarkMode = () => {
    const [cookies, setCookie,] = useCookies(['darkThemeActive']);
    const [darkMode, setDarkMode] = useState(cookies?.darkThemeActive?.isActive || window.matchMedia(cssProperty)?.matches);

    useEffect(() => {
        const media = window.matchMedia(cssProperty);
        const persist = () => {
            setCookie('darkThemeActive', JSON.stringify({ isActive: media.matches }), {
                path: '/',
                expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
            });
        }

        media.addListener(persist);

        return () => media.removeListener(persist);
    }, []);

    useEffect(() => {
        if (cookies?.darkThemeActive?.isActive !== undefined)
            setDarkMode(cookies?.darkThemeActive?.isActive);
    }, [cookies?.darkThemeActive]);

    return darkMode;
};

export default useDarkMode;
